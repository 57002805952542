import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListService } from 'src/app/services/permission/list.service';

import { ManagerMainPage } from './manager-main.page';

const routes: Routes = [
  {
    path: '',
    component: ManagerMainPage,
    
    
    
    children:[
      {
        path:'',
        redirectTo:'login',
        pathMatch:"full",
      },
     
      {

        path: 'customer-lookup',
        canActivate:[ListService],
        loadChildren: ()  => import('../manager/customer-lookup/customer-lookup.module').then(m => m.CustomerLookupPageModule)
      },
      {
        
        path: 'consultation',
        loadChildren: ()  => import('../manager/consultation/consultation.module').then(m => m.ConsultationPageModule)
      },
      {
        path: 'consultation-pet-info/:id/:customer/:session',
        loadChildren: ()  => import('../manager/consultation-pet-information/consultation-pet-information.module').then(m => m.ConsultationPetInformationPageModule)
      },
      {
        path: 'treatment-inpatient/:id/:consult/:appoint',
        loadChildren: ()  => import('../manager/treatment-inpatient/treatment-inpatient.module').then(m => m.TreatmentInpatientPageModule)
      },

      {
        path: 'roles-users',
        loadChildren: ()  => import('../manager/role-user/role-user.module').then(m => m.RoleUserPageModule)
      },
      {
        path: 'manage-users',
        loadChildren: () => import('../manager/manage-users/manage-users.module').then( m => m.ManageUsersPageModule)
      },
      {
        path: 'previous',
        loadChildren: () => import('../manager/previous-log/previous-log.module').then( m => m.PreviousLogPageModule)
      },
      {
        path: 'follow-up',
        loadChildren: () => import('../manager/follow-up/follow-up.module').then( m => m.FollowUpPageModule)
      },
      {
        
        path: 'edit-billing/:id/:type',
        loadChildren: ()  => import('../manager/edit-billing/edit-billing.module').then(m => m.EditBillingPageModule)
      },
      

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManagerMainPageRoutingModule {}
