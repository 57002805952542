import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
import { ViewDetailedLogComponent } from 'src/app/pages/manager/view-detailed-log/view-detailed-log.component';
@Component({
  selector: 'app-history-prescription',
  templateUrl: './history-prescription.component.html',
  styleUrls: ['./history-prescription.component.scss'],
})
export class HistoryPrescriptionComponent implements OnInit {
addForm:any;
  userProfile:any=localStorage.getItem('userProfile');
  viewDetailslog:any[]=[]
  constructor(
    public dialogRef: MatDialogRef<HistoryPrescriptionComponent>,
    private toast:ToastComponent,
    private crudService:CrudService,
    public dialog: MatDialog,

    @Inject(MAT_DIALOG_DATA)
    public data: any,

  ) {
    this.crudService.getbyid(this.data?.dogId, 'appointment/getDogLogs').then((res: any) => {
      this.viewDetailslog = res;
    })
    
  }

  ngOnInit() {}
  closedialog(){
    this.dialogRef.close();
  }
  logbtn(id: any) {


    const dialogref = this.dialog.open(ViewDetailedLogComponent, {

      data: {
        id: id
      },
      width: '80%',
      minWidth: '80%',
      panelClass: 'view-detail-log-main'
    });
  }
  
  
}

