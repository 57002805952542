import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingserviceService {
  loading : any;


  constructor() { }

  showLoading() {
     this.loading=true;
   }
 
   hideLoading() {
     this.loading=false;
   } 
}

