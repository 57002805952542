<div *ngIf="data.pet == 'PetSchedule'" class="petschedulebox">
  <div class="header">
    <p class="choose">Choose Patient</p>
    <ion-icon (click)="closedialog()" class="icon" name="close-outline"></ion-icon>
  </div>

  <div class="line">
    <img src="../../../../assets/Line 69.svg">
  </div>

  <div class="middle">
    <p>Time to play Patient detective!</p>
    <p>Choose a furry friend from the customer's Patient list.</p>

    <div class="petList">
      <ion-chip *ngFor="let data of petdata;let i =index" (click)="selectpet(data._id,data.name,i)"
        [ngClass]="selectpetval===data._id ? 'active' : ''" >
        <ion-icon name="checkmark" ></ion-icon>{{data.name}}
         <mat-form-field  class="datepicker">
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="data.appoinmentTime" name="data.appoinmentTime"  [min]="minDate" (dateInput)="show(data.appoinmentTime)" > 
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <ion-icon *ngIf="parentdata?._id != undefined" (click)="deleteappoint(parentdata?._id)" class="icon1" name="trash-outline"></ion-icon>
       
      </ion-chip>
      <p class="appgoingon" *ngIf="parentdata?._id != undefined">
        Appointment going on for <span (click)="openAppoint(parentdata._id)">{{parentdata?.dogs?.name}}</span> please delete or complete your ongoing appointment
      </p>
    </div>
  </div>


  <div class="modalFooter">

    <p>Create a <button style="background-color: transparent;"><a (click)="openpet(data.id,data.name);"
          class="newPetTextBt">New Patient</a> </button> instead</p>
    <div>
      <button class="modalSecondaryBt" (click)="closedialog()">Cancel</button>
      <button class="modalPrimaryBt" (click)="continuepage(petid,name)" [disabled]="!dateselect">Continue</button>
    </div>

  </div>
</div>


<div *ngIf="data.pet == 'PetCheck'">
  <div class="header">
    <p class="choose">Choose Check In Patient</p>
    <ion-icon (click)="closedialog()" class="icon" name="close-outline"></ion-icon>
  </div>

  <div class="line">
    <img src="../../../../assets/Line 69.svg">
  </div>

  <div class="middle">
    <p>Time to play Patient detective!</p>
    <p>Choose a furry friend from the customer's Patient list.</p>
    <div class="petList">
      <ion-chip *ngFor="let data of petdata;let i =index" (click)="selectpet1(data._id,data.name,i)"
        [ngClass]="selectpetval===data._id ? 'active' : ''">
        <ion-icon name="checkmark" ></ion-icon>{{data.name}}
    
       
        </ion-chip>
    </div>
  </div>


  <div class="modalFooter">

    <p>Create a <button style="background-color: transparent;"><a class="newPetTextBt"
          (click)="openpet(data.id,data.name);">New Patient</a>
      </button> instead</p>
    <div>
      <button class="modalSecondaryBt" (click)="closedialog()">Cancel</button>
      <button class="modalPrimaryBt" [disabled]="!selectpetval" (click)="continuecheck(petid,name)">Continue</button>
    </div>

  </div>
</div>

<div *ngIf="data.pet == 'editpet'">
  <div class="header">
    <p class="choose">Choose Edit Patient</p>
    <ion-icon (click)="closedialog()" class="icon" name="close-outline"></ion-icon>
  </div>

  <div class="line">
    <img src="../../../../assets/Line 69.svg">
  </div>

  <div class="middle">
    <p>Time to play Patient detective!</p>
    <p>Choose a furry friend from the customer's Patient list.</p>
    <div class="petList">
      <ion-chip *ngFor="let datas of petdata;let i =index" (click)="selectpet(datas._id,datas.name,i)"
        [ngClass]="selectpetval===datas._id ? 'active' : ''">
        <ion-icon name="checkmark"></ion-icon>{{datas.name}}
      </ion-chip>
    </div>
  </div>


  <div class="modalFooter">
    <p>Somthing Nice</p>
    <div>
      <button class="modalSecondaryBt" (click)="closedialog()">Cancel</button>
      <button class="modalPrimaryBt" [disabled]="!selectpetval" (click)="continueedit(petid)">Continue</button>
    </div>


  </div>
</div>