<div class="masterSkeleton" *ngIf="!skeleton">
  <div class="item skeNav">
    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
  </div>
  <div class="contents">
    <div class="table">
      <div class="tableControls">
        <div class="search">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
        <div class="button">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>

      </div>
      <div class="tableHeader">
        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
      </div>
      <div class="tableContents">
        <ngx-skeleton-loader count="10"></ngx-skeleton-loader>
      </div>
    </div>

  </div>
</div>

<div class="main-content" *ngIf="skeleton">
  <div class="top-header">
    <app-header></app-header>
  </div>
  <div class="table-content">
    <div class="bg-white">
      <div class="box-flex">
        <div>
          <input class="basic-input-field" matInput (keyup)="searchComplete()" [(ngModel)]="searchVariable"
            placeholder="Search..." #input>
        </div>
        <div class="add-inventory">
          <!--  (click)="openDialogadd('0ms','0ms')" -->
          <button class="button-add-inv" (click)="openCustomeraddd('true')">New Patient</button>
        </div>
      </div>

      <div class="mat-elevation-z8">
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID No</th>
              <td mat-cell *matCellDef="let row">{{row.uniqueId}}

              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name"> Account Name
              </th>
              <td mat-cell *matCellDef="let row"
                [ngClass]="row.treatedByYou == false ? 'not-treatedByYou' : 'treatedByYou'"> {{row.name}} </td>
            </ng-container>

            <!-- Profile Image Column -->
            <ng-container matColumnDef="profileimage">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Parent Profile Image </th>
              <td mat-cell *matCellDef="let row"
                [ngClass]="row.treatedByYou == false ? 'not-treatedByYou' : 'treatedByYou'">
                <span *ngIf="row.treatedByYou == true"> ***** </span>
                <span *ngIf="row.treatedByYou == false"> {{row.profileimage}} </span>
              </td>
            </ng-container>

            <!-- Mobile Column -->
            <ng-container matColumnDef="mobile">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile </th>
              <td mat-cell *matCellDef="let row"
                [ngClass]="row.treatedByYou == false ? 'not-treatedByYou' : 'treatedByYou'"> {{row.mobile}} </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Email </th>
              <td mat-cell *matCellDef="let row"
                [ngClass]="row.treatedByYou == false ? 'not-treatedByYou' : 'treatedByYou'"> {{row.email}}
                <span *ngIf="!row.email">-</span>
              </td>
            </ng-container>

            <!-- Registration Type Column -->
            <!-- <ng-container matColumnDef="registrationType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Treated By You </th>


              <td mat-cell *matCellDef="let row"
                [ngClass]="row.treatedByYou == false ? 'not-treatedByYou' : 'treatedByYou'">
                <span *ngIf="row.treatedByYou == true">Yes</span>
                <span *ngIf="row.treatedByYou == false"> No </span>

              </td>
            </ng-container> -->
            <!-- <span *ngFor="let data of row.dog">{{data.registrationType}}</span> -->
            <!-- House No. Column -->
            <!-- <ng-container matColumnDef="addresss">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
              <td mat-cell *matCellDef="let row"
                [ngClass]="row.treatedByYou == false ? 'not-treatedByYou' : 'treatedByYou'">

                <span
                  *ngIf="row.treatedByYou == true">{{row.houseno}}{{row.street}}{{row.landmark}}{{row.city}}{{row.address}}</span>

                <span *ngIf="row.treatedByYou == false"> ***** </span>

              </td>
            </ng-container> -->



            <!-- Notes Column -->


            <!-- Created At Column -->
            <!-- <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
              <td mat-cell *matCellDef="let row"
                [ngClass]="row.treatedByYou == false ? 'not-treatedByYou' : 'treatedByYou'">
                <span *ngIf="row.treatedByYou == false"> ***** </span>
                <span *ngIf="row.treatedByYou == true"> {{row.createdAt | date: 'dd-MM-yyyy' }} </span>
              </td>
            </ng-container> -->

            <!-- Pet Name Column -->
            <ng-container matColumnDef="petname" sticky>
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent
              </th>
              <td mat-cell *matCellDef="let row"
                [ngClass]="row.treatedByYou == false ? 'not-treatedByYou' : 'treatedByYou'">
                <button class="plus_bt" (click)="openpetForm('true',row._id,row.name)">
                  <img src="../../../assets/Vector.svg">
                </button>

                <span *ngFor="let data of row.dog; let i = index" class="petsName">
                  <strong (click)="viewprofile(data._id,row._id)">{{data.name}}{{i!=row.dog.length-1 ? ',' :
                    ''}}</strong>
                  <!-- <span *ngIf="data.species"> ({{data.species}}) </span> -->
                </span>

              </td>
            </ng-container>

            <!-- Images Column -->
            <ng-container matColumnDef="images">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Images
              </th>
              <td mat-cell *matCellDef="let row">
                <span *ngFor="let data of row.dog">{{data.images}}</span>

              </td>
            </ng-container>



            <!-- Age Column -->
            <ng-container matColumnDef="year">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Age
              </th>
              <td mat-cell *matCellDef="let row">
                <span *ngFor="let data of row.dog">{{data.year}},</span>

              </td>
            </ng-container>





            <!-- Register Date Column -->
            <ng-container matColumnDef="registerDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Register Date
              </th>
              <td mat-cell *matCellDef="let row">
                <span *ngFor="let data of row.dog">{{data.registerDate}}</span>

              </td>
            </ng-container>

            <!-- Last Visit Column -->
            <ng-container matColumnDef="lastvisit">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Visit
              </th>
              <td mat-cell *matCellDef="let row"
                [ngClass]="row.treatedByYou == false ? 'not-treatedByYou' : 'treatedByYou'">
                <span *ngIf="row.treatedByYou == false"> ***** </span>
                <span *ngFor="let data of row.dog">
                  <span *ngIf="row.treatedByYou == true">{{data.lastvisit | date: 'dd-MMMM-yyyy'}}, </span></span>

              </td>
            </ng-container>

            <!-- Follow Up Column -->
            <ng-container matColumnDef="followup">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Follow Up
              </th>
              <td mat-cell *matCellDef="let row"
                [ngClass]="row.treatedByYou == false ? 'not-treatedByYou' : 'treatedByYou'">
                <span *ngIf="row.treatedByYou == false"> ***** </span>

                <span *ngFor="let data of row.dog">
                  <span *ngIf="row.treatedByYou == false"> {{data.nextvisitDate}}</span>

                </span>

              </td>
            </ng-container>

            <!-- Vaccination Due Column -->
            <ng-container matColumnDef="vaccinationDue">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Vaccination Due
              </th>
              <td mat-cell *matCellDef="let row">
                <span *ngFor="let data of row.dog">{{data.vaccinationDue}}</span>

              </td>
            </ng-container>

            <!-- Blood Column -->
            <ng-container matColumnDef="blood">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Blood
              </th>
              <td mat-cell *matCellDef="let row">
                <span *ngFor="let data of row.dog">{{data.blood}}</span>

              </td>
            </ng-container>

            <!-- Virtually Adoption User Id Column -->
            <ng-container matColumnDef="virtuallyAdoptionUserId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header mat-sort-header>Virtually Adoption User Id
              </th>
              <td mat-cell *matCellDef="let row">
                <span *ngFor="let data of row.dog">{{data.virtuallyAdoptionUserId}}</span>

              </td>
            </ng-container>

            <!-- Sell Column -->
            <ng-container matColumnDef="sell">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Sell
              </th>
              <td mat-cell *matCellDef="let row">
                <span *ngFor="let data of row.dog">{{data.sell}}</span>

              </td>
            </ng-container>

            <!-- Vaccination Column -->
            <ng-container matColumnDef="vaccination">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Vaccination
              </th>
              <td mat-cell *matCellDef="let row">
                <span *ngFor="let data of row.dog">{{data.vaccination}}</span>

              </td>
            </ng-container>

            <!-- Pet Created At Column -->
            <ng-container matColumnDef="petcreatedAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At
              </th>
              <td mat-cell *matCellDef="let row">
                <span *ngFor="let data of row.dog">{{data.createdAt}}</span>

              </td>
            </ng-container>

            <ng-container matColumnDef="action" stickyEnd>
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
              <td mat-cell *matCellDef="let row" class="actionbtn">
                <div class="button-actions">
                  <div class="twoButtons">
                    <div>
                      <ion-button *ngIf="row?.dog?.length > 1" class="schedule" (click)="openDialog(row._id,row.name)">
                        Schedule</ion-button>
                      <span *ngIf="row?.dog?.length == 1">

                        <ion-button class="schedule"
                          (click)="openDialog(row?._id,row?.name)">Schedule</ion-button>
                      </span>
                    </div>
                    <div>

                      <ion-button class="viewp" *ngIf="row?.dog?.length > 1" (click)="openDialogpet(row._id,row.name)">
                        Check
                        In</ion-button>

                      <span *ngIf="row?.dog?.length == 1">

                        <ion-button class="viewp" *ngFor="let data of row?.dog"
                          (click)="checkin(data?._id,data?.name,row._id)">Check In</ion-button>
                      </span>
                    </div>
                  </div>
                  <!-- <ion-button (click)="edit(row._id);openCustomeredit('true')">
                    <img src="../../../assets/ruler&pen.svg">
                  </ion-button> -->
                  <ion-button (click)="multipleEdit(row._id)">
                    <img src="../../../assets/ruler&pen.svg">
                  </ion-button>
                  <!-- <ion-button>
                    <img src="../../../assets/ArrowRight.svg">
                  </ion-button> -->
                </div>
              </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4" class="search-feedback">No matching result found
              </td>
            </tr>
          </table>

        </div>

        <!-- <mat-paginator class="mat-paginator-sticky" [length]="totalCount" [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions" (page)="getServerData($event)" [showFirstLastButtons]="true">>
        </mat-paginator> -->
        <mat-paginator class="mat-paginator-sticky" [pageSize]="25" [pageSizeOptions]="pageSizeOptions"
          showFirstLastButtons #paginator>
        </mat-paginator>
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
      </div>
    </div>
  </div>

</div>




<div [ngClass]="open===true ? 'main parent-account parent-account-open' : 'main parent-account'">
  <div class="back-box">

    <div class="img-box">
      <div>
        <span (click)="openCustomeraddd('false')" id="click-item">
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="24" viewBox="0 0 33 24" fill="none">
            <path d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008"
              stroke="#4E5562" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M17.9102 19.9201L24.4302 13.4001C25.2002 12.6301 25.2002 11.3701 24.4302 10.6001L17.9102 4.08008"
              stroke="#4E5562" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </span>
        <p class="parent-text">Creating Parent Account</p>
      </div>
    </div>
    <div class="toggle-btn-box">
      <span>
        <mat-slide-toggle class="toggle-btn-value" [checked]="checked" (click)="advance(value)">Mandatory
          Only</mat-slide-toggle>
      </span>
    </div>
  </div>
  <div class="form-inner-box">



    <div class="form-bg-box">
      <form #Form="ngForm" (ngSubmit)="save(Form)">
        <div class="container-feilds">
          <div class="full-input">


            <mat-form-field class="input-box-value mando" appearance="fill" [floatLabel]="'always'">
              <mat-label>Parent</mat-label>
              <input matInput placeholder="Name" [(ngModel)]="addForm.parentDetails.name"
                name="addForm.parentDetails.name" required>
            </mat-form-field>
          </div>
          <div class="mat-bg-box">
            <div style="display: flex; gap:10px;">
              <mat-form-field class="parent-input-btn  input-box-value mando" style="flex-basis:40%;" appearance="fill"
                [floatLabel]="'always'">
                <mat-label>mobile</mat-label>
                <input matInput placeholder="Mobile number" #mobNo [(ngModel)]="addForm.parentDetails.mobile"
                  name="addForm.parentDetails.mobile" (input)="lengthvalid($event)" type="number" required>
              </mat-form-field>
              <div *ngIf="addForm.parentDetails.mobile && lenMatch">
                <span style="color:red" class="error validclassboxbelow">enter valid contact numbers</span>
              </div>
              <mat-form-field class="parent-input-btn input-box-value mando" style="flex-basis:60%;" appearance="fill"
                [floatLabel]="'always'">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Enter" [(ngModel)]="addForm.parentDetails.email"
                  name="addForm.parentDetails.email" type="email" email="true" (input)="emailvalid(addForm.parentDetails.email)">
              </mat-form-field>
             
              <div *ngIf="addForm.parentDetails.email && emailfield">
                <span style="color:red" class="error validclassboxbelow">enter valid email address</span>
              </div>
            </div>

            <div style="display:flex; gap:10px;">
              
              <mat-form-field appearance="fill" [floatLabel]="'always'">
                <mat-label>Age</mat-label>
                <input matInput placeholder="Age" [(ngModel)]="addForm.parentDetails.age"
                  name="addForm.parentDetails.age" required>
              </mat-form-field>
             
            </div>

            <div *ngIf="!detail" style="display:flex; gap:10px;">

              <mat-form-field class="eamil-box input-box-value" appearance="fill" [floatLabel]="'always'">
                <mat-label>Aadhar Number</mat-label>
                <input type="number" matInput placeholder="Aadhar Number"   [(ngModel)]="addForm.parentDetails.aadharNumber"
                  name="addForm.parentDetails.aadharNumber" 
                  (input)="adharlength($event)" >
              </mat-form-field>
              <div *ngIf="addForm.parentDetails.aadharNumber && contactlength">
                <span style="color:red" class="error validclassboxbelow">enter valid aadhar number</span>
              </div>
            </div>
            <div>
              <mat-form-field appearance="fill" *ngIf="!detail" [floatLabel]="'always'">
                <mat-label>Address</mat-label>
                <input matInput placeholder="Enter" [(ngModel)]="addForm.parentDetails.address"
                  name="addForm.parentDetails.address">
              </mat-form-field>
            </div>



          </div>


          <div class="col-12 petInfo">
            <div class="pet-det-add">
              <h2 class="AllLabels">Patient Details
              </h2>
              <div class="petDetailsCurve">

              </div>

            </div>
            <!-- <label class="AllLabelContainer">
              
            </label>
            <div class="" >
              <label class="AllLabelContainer">
                <h2 class="AllLabels">Actions</h2>
                
              </label>
            </div> -->
            <div class="row row-box-pet">
              <!-- mandatory pets info -->
              <div style="width:100%">
                <div style="display: flex;
                gap: 10px;
                width: 100%;">

                  <mat-form-field class="eamil-box input-box-value mando" appearance="fill" [floatLabel]="'always'">
                    <mat-label>Patient</mat-label>
                    <input matInput placeholder="Name" [(ngModel)]="addForm.petDetails.name"
                      name="addForm.petDetails.name" #name="ngModel" required>
                  </mat-form-field>



                  <mat-form-field class="eamil-box input-box-value" appearance="fill" [floatLabel]="'always'"
                    *ngIf="!detail">
                    <mat-label>Relation</mat-label>
                    <mat-select (selectionChange)="doSomething()" [(ngModel)]="addForm.petDetails.species"
                      name="addForm.petDetails.species" placeholder="Select">
                      <mat-option *ngFor="let petSpecie of petSpecies" [value]="petSpecie.viewValue">
                        {{petSpecie.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- 
                  <mat-form-field [floatLabel]="'always'" *ngIf="!detail">
                    <mat-label>Pet Breed </mat-label>
                    <mat-select class="pet-type" [(ngModel)]="addForm.petDetails.breed" name="addForm.petDetails.breed"
                      placeholder="Choose Breed" placeholder="Select">
                      <ng-container *ngFor="let petSpecie of petSpecies">
                        <ng-container *ngIf="petSpecie.viewValue == addForm.petDetails.species">
                          <mat-option *ngFor="let item of petSpecie.pettype;" [value]="item.value">
                            {{item.value}}
                          </mat-option>
                        </ng-container>
                      </ng-container>
                    </mat-select>
                  </mat-form-field> -->



                </div>

                <div>

                  <div style="display: flex; gap:10px" *ngIf="!detail">
                    <!-- 
                    <mat-form-field class="example-full-width" appearance="fill" [floatLabel]="'always'">
                      <mat-label> Mood</mat-label>
                      <mat-select [(ngModel)]="addForm.petDetails.mood" name="addForm.petDetails.mood"
                        placeholder="Pet Mood" placeholer="Select">
                        <mat-option value="Happy">Happy</mat-option>
                        <mat-option value="Sad">Sad</mat-option>
                        <mat-option value="Excited">Excited</mat-option>
                        <mat-option value="Anxious">Anxious</mat-option>
                        <mat-option value="Calm">Calm</mat-option>
                        <mat-option value="Content">Content</mat-option>
                        <mat-option value="Frustrated">Frustrated</mat-option>
                        <mat-option value="Playful">Playful</mat-option>
                        <mat-option value="Agitated">Agitated</mat-option>
                      </mat-select>
                  </mat-form-field> -->
                    <mat-form-field [floatLabel]="'always'">

                      <mat-label>Patient Age</mat-label>
                      <input matInput placeholder="Patient age" class="pet-age" [(ngModel)]="addForm.petDetails.age"
                        name="addForm.petDetails.age" #name="ngModel" required>
                    </mat-form-field>
                    <mat-form-field [floatLabel]="'always'" *ngIf="!detail">
                      <mat-label>Month</mat-label>
                      <mat-select class="pet-month" [(ngModel)]="addForm.petDetails.month"
                        name="addForm.petDetails.month" placeholder="Choose Month" placeholder="Month">
                        <mat-option *ngFor="let month of [].constructor(12);index as m;" [value]="m">
                          {{m}}
                        </mat-option>

                      </mat-select>
                    </mat-form-field>
                  </div>






                </div>




              </div>


              <!-- optional info on pets details -->
              <div style="width:100%;" *ngIf="!detail">
                <div *ngIf="!detail" style="display:flex; gap:10px;">
                  <mat-form-field appearance="fill" [floatLabel]="'always'">
                    <mat-label>Preferred Doctor</mat-label>
                    <input matInput placeholder="Name" [(ngModel)]="addForm.petDetails.preferedDoctor"
                      name="addForm.petDetails.preferedDoctor">
                  </mat-form-field>
                  <mat-form-field [floatLabel]="'always'" [floatLabel]="'always'">
                    <mat-label>Gender</mat-label>
                    <mat-select class="pet-type" [(ngModel)]="addForm.petDetails.gender"
                      name="addForm.petDetails.gender" placeholder="Select">
                      <mat-option value="Male">
                        Male
                      </mat-option>
                      <mat-option value="Female">
                        Female
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>





                <div *ngIf="!detail" style="display:flex; gap:10px;">
                  <mat-form-field appearance="fill" [floatLabel]="'always'">
                    <mat-label>Mother Name</mat-label>
                    <input matInput placeholder="Name" [(ngModel)]="addForm.petDetails.petmotherName"
                      name="addForm.petDetails.petmotherName">
                  </mat-form-field>
                  <mat-form-field appearance="fill" [floatLabel]="'always'">
                    <mat-label>Blood Groups</mat-label>
                    <mat-select class="pet-type" [(ngModel)]="addForm.petDetails.blood" name="addForm.petDetails.blood"
                      placeholder="Select">

                      <mat-option value="A+">A+</mat-option>
                      <mat-option value="A-">A-</mat-option>
                      <mat-option value="B+">B+</mat-option>
                      <mat-option value="B-">B-</mat-option>
                      <mat-option value="AB+">AB+</mat-option>
                      <mat-option value="AB-">AB-</mat-option>
                      <mat-option value="O+">O+</mat-option>
                      <mat-option value="O-">O-</mat-option>

                    </mat-select>
                  </mat-form-field>
                </div>









              </div>


            </div>

            <!-- <mat-form-field >
                    <mat-label>Guardian / Spouse</mat-label>
                    <input matInput placeholder="Guardian/Spouse Name" [(ngModel)]="addForm.mobile" name="addForm.mobile">
                </mat-form-field>
                <mat-form-field >
                    <mat-label>Guardian / Spouse Email</mat-label>
                    <input matInput placeholder="Email">
                </mat-form-field>
                <mat-form-field >
                    <mat-label>Govt Identification</mat-label>
                    <mat-select>
                        <mat-option>Aadhar</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field >
                    <mat-label>Aadhar Number</mat-label>
                    <input matInput placeholder="Aadhar Number">
                </mat-form-field>
                <input type="file" /> -->

          </div>
          <!-- End of Advance Parent -->
        </div>
        <div class="last-btn-box">

          <ion-button style="order: 2;" class="cancel-btn" mat-flat-button (click)="openCustomeraddd('false')">Cancel
          </ion-button>
          <ion-button style="order: 1;" class="parent-btn" type="submit" mat-flat-button [disabled]="Form.invalid">
            Create
            Parent</ion-button>

        </div>
      </form>
    </div>

  </div>
</div>



<!-- Edit page -->


<div [ngClass]="editpage===true ? 'main parent-account parent-account-open' : 'main parent-account'">
  <div class="back-box">

    <div class="img-box">
      <div>
        <span (click)="openCustomeredit('false')"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
            viewBox="0 0 25 25" fill="none">
            <path d="M19.5009 12.8013H5.64551" stroke="black" stroke-width="1.85563" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M12.5732 19.7294L5.64551 12.8017L12.5732 5.87402" stroke="black" stroke-width="1.85563"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg></span>
        <p class="parent-text">Editing Account</p>
      </div>
    </div>
    <!-- <div class="toggle-btn-box">
      <span><mat-slide-toggle class="toggle-btn-value" [checked]="checked" (click)="advance(value)">Mandatory
          Only</mat-slide-toggle></span>
    </div> -->
  </div>
  <div class="form-inner-box">



    <div class="form-bg-box">
      <form #Form2="ngForm" (ngSubmit)="editsave(Form2)">
        <div class="full-input">


          <!-- <mat-form-field class="input-box-value" appearance="fill" [floatLabel]="'always'">
            <mat-label>Account Name</mat-label>
            <input matInput placeholder="Account Name" [(ngModel)]="editForm.parentDetails.name"
              name="editForm.parentDetails.name" required>
          </mat-form-field> -->
        </div>
        <div class="mat-bg-box">
          <div class="email-num">
            <div>
              <mat-form-field class="parent-input-btn  input-box-value " appearance="fill" [floatLabel]="'always'">
                <mat-label>mobile</mat-label>
                <input matInput placeholder="Parent mobile" [(ngModel)]="editForm.parentDetails.mobile"
                  name="editForm.parentDetails.mobile" type="number" (input)="lengthvalid($event)" required>
              </mat-form-field>
              <div *ngIf="editForm.parentDetails.mobile && lenMatch">
                <span style="color:red" class="error validclassboxbelow">enter valid contact number</span>
              </div>
              <mat-form-field class="eamil-box input-box-value" appearance="fill" [floatLabel]="'always'">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Parent Email" [(ngModel)]="editForm.parentDetails.email" (input)="emailvalid(editForm.parentDetails.email)"
                  name="editForm.parentDetails.email" type="email" email="true">
              </mat-form-field>
              
              <div *ngIf="editForm.parentDetails.email && emailfield">
                <span style="color:red" class="error validclassboxbelow">enter valid email address</span>
              </div>
            </div>
            <div>

              <mat-form-field appearance="fill" [floatLabel]="'always'">
                <mat-label>Age</mat-label>
                <input matInput placeholder="Age" [(ngModel)]="editForm.parentDetails.age"
                  name="editForm.parentDetails.age" required>
              </mat-form-field>

            </div>
            <mat-form-field class="input-box-value" appearance="fill" [floatLabel]="'always'">
              <mat-label>Account Name</mat-label>
              <input matInput placeholder="Account Name" [(ngModel)]="editForm.parentDetails.name"
                name="editForm.parentDetails.name" required>
            </mat-form-field>
          </div>
          
          <mat-form-field appearance="fill" [floatLabel]="'always'">
            <mat-label>Address</mat-label>
            <input matInput placeholder="Address" [(ngModel)]="editForm.parentDetails.address"
              name="editForm.parentDetails.address">
          </mat-form-field>



          <div style="display:flex; gap:10px;">
            <!-- <mat-form-field appearance="fill" [floatLabel]="'always'">
              <mat-label>Govt ID</mat-label>
              <mat-select (selectionChange)="govtId()" [(ngModel)]="editForm.parentDetails.guardianId"
                name="editForm.parentDetails.guardianId" placeholder="Aadhar">
                <mat-option *ngFor="let customerinfoIDs of customerinfoID" [value]="customerinfoIDs.viewValue">
                  {{customerinfoIDs.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <mat-form-field class="eamil-box input-box-value" appearance="fill" [floatLabel]="'always'">
              <mat-label>Aadhar Number</mat-label>
              <input matInput placeholder="Aadhar Number" [(ngModel)]="editForm.parentDetails.aadharNumber" type="number"
                name="editForm.parentDetails.aadharNumber"  (input)="adharlength($event)">               
            </mat-form-field>
            <div *ngIf="editForm.parentDetails.aadharNumber && contactlength">
              <span style="color:red" class="error validclassboxbelow">enter valid aadhar number</span>
            </div>
          </div>


        </div>


        <div class="col-12">
          <div class="pet-det-add">
            <h2 class="AllLabels">Patient Details
            </h2>
            <div class="petDetailsCurve">

            </div>
          </div>
          <!-- <label class="AllLabelContainer">
            
          </label>
          <div class="" >
            <label class="AllLabelContainer">
              <h2 class="AllLabels">Actions</h2>
              
            </label>
          </div> -->
          <div class="row row-box-pet">
            <div class="w-50">
              <mat-form-field class="eamil-box input-box-value" appearance="fill" [floatLabel]="'always'">
                <mat-label>Patient Name</mat-label>
                <input matInput placeholder="Patient Name" [(ngModel)]="editForm.petDetails.name"
                  name="editForm.petDetails.name" #name="ngModel" required>
              </mat-form-field>

            </div>

            <div class="w-25">
              <mat-form-field class="eamil-box input-box-value" appearance="fill">
                <mat-label>Relation</mat-label>
                <mat-select (selectionChange)="doSomething()" [(ngModel)]="editForm.petDetails.species"
                  name="editForm.petDetails.species">
                  <mat-option *ngIf="editForm.petDetails.species" [disabled]="true"
                    [value]="editForm.petDetails.species">
                    {{editForm.petDetails.species}}
                  </mat-option>
                  <mat-option *ngFor="let petSpecie of petSpecies" [value]="petSpecie.viewValue">
                    {{petSpecie.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="w-20">
              <mat-form-field class="eamil-box input-box-value" appearance="fill" [floatLabel]="'always'">
                <mat-label>Weight</mat-label>
                <input matInput placeholder="Patient" [(ngModel)]="editForm.petDetails.weight"
                  name="editForm.petDetails.weight">
              </mat-form-field>
            </div>
            <div class="w-60">
              <mat-form-field appearance="fill" [floatLabel]="'always'">
                <mat-label>Preferred Doctor</mat-label>
                <input matInput placeholder="Preferred Doctor" [(ngModel)]="editForm.petDetails.preferedDoctor"
                  name="editForm.petDetails.preferedDoctor">
              </mat-form-field>
            </div>
            <!-- Breed -->
            <!-- <div class="w-35">
              <mat-form-field>
                <mat-label>Pet Breed</mat-label>
                <mat-select class="pet-type" [(ngModel)]="editForm.petDetails.breed" name="editForm.petDetails.breed">
                  <mat-option [disabled]="true" [value]="editForm.petDetails.breed">
                    {{editForm.petDetails.breed}}
                  </mat-option>
                  <ng-container *ngFor="let petSpecie of petSpecies">
                    <ng-container *ngIf="petSpecie.viewValue == editForm.petDetails.species">
                      <mat-option *ngFor="let item of petSpecie.pettype;" [value]="item.value">
                        {{item.value}}
                      </mat-option>
                    </ng-container>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div> -->

            <!-- Gender -->
            <div class="w-30">
              <mat-form-field>
                <mat-label>Gender</mat-label>
                <mat-select class="pet-type" [(ngModel)]="editForm.petDetails.gender" name="editForm.petDetails.gender">
                  <mat-option [disabled]="true" [value]="editForm.petDetails.gender">
                    {{editForm.petDetails.gender}}
                  </mat-option>

                  <mat-option value="Gender">
                    Male
                  </mat-option>

                  <mat-option value="Gender">
                    Female
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Color -->

            <!-- Mood -->
            <!-- <div class="w-30">

              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label> Mood</mat-label>
                <mat-select [(ngModel)]="editForm.petDetails.mood" name="editForm.petDetails.mood">
                  <mat-option value="Happy">Happy</mat-option>
                  <mat-option value="Sad">Sad</mat-option>
                  <mat-option value="Excited">Excited</mat-option>
                  <mat-option value="Anxious">Anxious</mat-option>
                  <mat-option value="Calm">Calm</mat-option>
                  <mat-option value="Content">Content</mat-option>
                  <mat-option value="Frustrated">Frustrated</mat-option>
                  <mat-option value="Playful">Playful</mat-option>
                  <mat-option value="Agitated">Agitated</mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->

            <!-- Age -->
            <div class="w-30">
             
              <mat-form-field [floatLabel]="'always'">

                <mat-label>Patient Age</mat-label>
                <input matInput placeholder="Patient age" class="pet-age" [(ngModel)]="editForm.petDetails.age" name="editForm.petDetails.age" #name="ngModel" required>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <mat-label>Month</mat-label>
                <mat-select class="pet-month" [(ngModel)]="editForm.petDetails.month" name="editForm.petDetails.month">
                  <mat-option [disabled]="true" [value]="editForm.petDetails.month">
                    {{editForm.petDetails.month}}
                  </mat-option>
                  <mat-option *ngFor="let month of [].constructor(12);index as m;" [value]="m">
                    {{m}}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>

            <div class="w-35">
              <mat-form-field appearance="fill" [floatLabel]="'always'">
                <mat-label> Mother Name</mat-label>
                <input matInput placeholder=" Mother Name" [(ngModel)]="editForm.petDetails.petmotherName"
                  name="editForm.petDetails.petmotherName">
              </mat-form-field>
            </div>
            <div class="w-20">
              <mat-form-field>
                <mat-label>Blood Groups</mat-label>
                <mat-select class="pet-type" [(ngModel)]="editForm.petDetails.blood" name="editForm.petDetails.blood"
                  placeholder="Select">
                  <mat-option value="A+">A+</mat-option>
                  <mat-option value="A-">A-</mat-option>
                  <mat-option value="B+">B+</mat-option>
                  <mat-option value="B-">B-</mat-option>
                  <mat-option value="AB+">AB+</mat-option>
                  <mat-option value="AB-">AB-</mat-option>
                  <mat-option value="O+">O+</mat-option>
                  <mat-option value="O-">O-</mat-option>
                </mat-select>
              </mat-form-field>
            </div>


            <!-- <div class="w-30">
              <mat-form-field appearance="fill" [floatLabel]="'always'">
                <mat-label>Registration Date</mat-label>
                <input matInput [matDatepicker]="picker1" [(ngModel)]="editForm.petDetails.registerDate"
                  name="editForm.petDetails.registerDate">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div> -->





          </div>

          <!-- <mat-form-field >
                  <mat-label>Guardian / Spouse</mat-label>
                  <input matInput placeholder="Guardian/Spouse Name" [(ngModel)]="addForm.mobile" name="addForm.mobile">
              </mat-form-field>
              <mat-form-field >
                  <mat-label>Guardian / Spouse Email</mat-label>
                  <input matInput placeholder="Email">
              </mat-form-field>
              <mat-form-field >
                  <mat-label>Govt Identification</mat-label>
                  <mat-select>
                      <mat-option>Aadhar</mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-form-field >
                  <mat-label>Aadhar Number</mat-label>
                  <input matInput placeholder="Aadhar Number">
              </mat-form-field>
              <input type="file" /> -->

        </div>
        <!-- End of Advance Parent -->
        <div class="last-btn-box">
          <ion-button class="cancel-btn" mat-flat-button (click)="openCustomeredit('false')">Cancel</ion-button>
          <ion-button class="parent-btn" type="submit" mat-flat-button [disabled]="Form2.invalid">Save
            Details</ion-button>
        </div>
      </form>
    </div>

  </div>
</div>

<!-- Add new Pet -->

<div [ngClass]="openpet===true ? 'main parent-account parent-account-open' : 'main parent-account'">
  <div class="back-box">
    <div class="img-box">
      <div>
        <span (click)="openpets('false')" style="cursor: pointer;">
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="24" viewBox="0 0 33 24" fill="none">
            <path d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008"
              stroke="#4E5562" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M17.9102 19.9201L24.4302 13.4001C25.2002 12.6301 25.2002 11.3701 24.4302 10.6001L17.9102 4.08008"
              stroke="#4E5562" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </span>
        <p class="parent-text">New Patient Form</p>
      </div>
      <!-- <div class="toggle-btn-box">
        <span><mat-slide-toggle class="toggle-btn-value" [checked]="checkedpet" (click)="advancepet(value)">Mandatory
            Only</mat-slide-toggle></span>
      </div> -->
    </div>
  </div>

  <div class="form-inner-box">
    <div class="form-bg-box">
      <form #Form1="ngForm" (ngSubmit)="savePet(Form1)">
        <div class="container-feilds">
          <div class="col-12">

            <!-- <label class="AllLabelContainer">
              
            </label>
            <div class="" >
              <label class="AllLabelContainer">
                <h2 class="AllLabels">Actions</h2>
                
              </label>
            </div> -->
            <div class="row row-box-pet">
              <div class="inputRow">

                <mat-form-field class="eamil-box input-box-value mando" appearance="fill" [floatLabel]="'always'">
                  <mat-label>Patient Name</mat-label>
                  <input matInput placeholder="Patient Name" [(ngModel)]="petForm.name" name="petForm..name"
                    #name="ngModel" required>
                </mat-form-field>


                <mat-form-field class="eamil-box input-box-value mando" appearance="fill" [floatLabel]="'always'">
                  <mat-label>Relation</mat-label>
                  <mat-select (selectionChange)="doSomething()" placeholder="Select" [(ngModel)]="petForm.species"
                    name="petForm.species" required>
                    <mat-option *ngFor="let petSpecie of petSpecies" [value]="petSpecie.viewValue">
                      {{petSpecie.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="eamil-box input-box-value" appearance="fill" [floatLabel]="'always'">
                  <mat-label>Weight</mat-label>
                  <input matInput placeholder="Weight" [(ngModel)]="petForm.weight" name="petForm.weight">
                </mat-form-field>

              </div>
              <div class="inputRow">
                <!-- <mat-form-field class="mando" [floatLabel]="'always'">
                  <mat-label>Pet Breed </mat-label>
                  <mat-select class="pet-type" [(ngModel)]="petForm.breed" name="petForm.breed" required
                    placeholder="Select">
                    <ng-container *ngFor="let petSpecie of petSpecies">
                      <ng-container *ngIf="petSpecie.viewValue == petForm.species">
                        <mat-option *ngFor="let item of petSpecie.pettype;" [value]="item.value">
                          {{item.value}}
                        </mat-option>
                      </ng-container>
                    </ng-container>
                  </mat-select>
                </mat-form-field> -->
                <mat-form-field appearance="fill" [floatLabel]="'always'">
                  <mat-label>Preferred Doctor</mat-label>
                  <input matInput placeholder="Preferred Doctor" [(ngModel)]="petForm.preferedDoctor"
                    name="petForm.preferedDoctor">
                </mat-form-field>

                <mat-form-field [floatLabel]="'always'">
                  <mat-label>Gender</mat-label>
                  <mat-select placeholder="Select" class="pet-type" [(ngModel)]="petForm.gender"
                    name="addForm.petDetails.gender">

                    <mat-option value="Male">
                      Male
                    </mat-option>
                    <mat-option value="Female">
                      Female
                    </mat-option>

                  </mat-select>
                </mat-form-field>
              </div>


              <div class="inputRow">

                <div class="inputExtended" style="width: 49%;">
                  <mat-form-field class="left" [floatLabel]="'always'">
                    <mat-label>Patient Age</mat-label>
                    
                    <input matInput placeholder="age"  class="pet-age" [(ngModel)]="petForm.age" name="petForm.age"
                       #name="ngModel" required>
                  </mat-form-field>
                  
                  <mat-form-field class="right" [floatLabel]="'always'">
                    <mat-label>Month</mat-label>
                    <mat-select placeholder="Select" class="pet-month" [(ngModel)]="petForm.month" name="petForm.month">
                      <mat-option *ngFor="let month of [].constructor(12);index as m;" [value]="m">
                        {{m}}
                      </mat-option>

                    </mat-select>
                  </mat-form-field>
                </div>
              </div>


              <div>

              </div>

              <div class="inputRow">
                <mat-form-field appearance="fill" [floatLabel]="'always'">
                  <mat-label> Mother Name</mat-label>
                  <input matInput placeholder="Name" [(ngModel)]="petForm.petmotherName" name="petForm.petmotherName">
                </mat-form-field>
                <mat-form-field [floatLabel]="'always'">
                  <mat-label>Blood Groups</mat-label>
                  <mat-select placeholder="Select" class="pet-type" [(ngModel)]="petForm.blood" name="petForm.blood">

                    <mat-option value="A+">A+</mat-option>
                    <mat-option value="A-">A-</mat-option>
                    <mat-option value="B+">B+</mat-option>
                    <mat-option value="B-">B-</mat-option>
                    <mat-option value="AB+">AB+</mat-option>
                    <mat-option value="AB-">AB-</mat-option>
                    <mat-option value="O+">O+</mat-option>
                    <mat-option value="O-">O-</mat-option>

                  </mat-select>
                </mat-form-field>
              </div>









            </div>

            <!-- <mat-form-field >
                    <mat-label>Guardian / Spouse</mat-label>
                    <input matInput placeholder="Guardian/Spouse Name" [(ngModel)]="addForm.mobile" name="addForm.mobile">
                </mat-form-field>
                <mat-form-field >
                    <mat-label>Guardian / Spouse Email</mat-label>
                    <input matInput placeholder="Email">
                </mat-form-field>
                <mat-form-field >
                    <mat-label>Govt Identification</mat-label>
                    <mat-select>
                        <mat-option>Aadhar</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field >
                    <mat-label>Aadhar Number</mat-label>
                    <input matInput placeholder="Aadhar Number">
                </mat-form-field>
                <input type="file" /> -->

          </div>
          <!-- End of Advance Parent -->
        </div>
        <div class="last-btn-box">
          <ion-button class="cancel-btn" mat-flat-button (click)="openpets('false')">Cancel</ion-button>
          <ion-button class="parent-btn" type="submit" mat-flat-button [disabled]="Form1.invalid">Create
            Patient</ion-button>
        </div>
      </form>
    </div>

  </div>
</div>