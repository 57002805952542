<div>
  <p>Creating User 2</p>
  <mat-icon aria-hidden="false" aria-label="Example exit icon" fontIcon="exit"></mat-icon>
</div>

<div>
  <mat-form-field>
    <mat-label>First Name</mat-label>
    <input matInput type="text" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Last Name</mat-label>
    <input matInput type="text" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Phone</mat-label>
    <input matInput type="tel" />
  </mat-form-field>
</div>

<div>
  <p>Clinic Access</p>
  <button mat-button>JP Nagar</button>
  <button mat-button>Bannargatta Rd</button>
</div>

<div>
  <p>Role</p>
  <button mat-button>Manager</button>
  <button mat-button>Doctor</button>
  <button mat-button>Front Desk</button>
</div>

<div>
  <p>You're about to welcome a new team member</p>
  <button mat-button>Cancel</button>
  <button mat-button>Create</button>
</div>