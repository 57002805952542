<div class="inventory-edit">
  <div class="center-modal-header">

    <div>
      <h1>Add Prescription</h1>

    </div>
    <div style="padding-top: 6px;
    padding-right: 7px;">
      <button class="close-button" (click)="closedialog()"><img src="../assets/close-icon.svg"></button>
    </div>

  </div>
</div>
<div>
  <ion-row class="canvarow">
    <canvas #canvas34 id="canvas34" width="600" height="700"></canvas>

  </ion-row>
  <div class="modalFooter">

    <div>
      <button [disabled]="spinner" (click)="saveSignature()">Save<ion-item *ngIf="spinner" class="spinneraddprescript"> <ion-spinner name="crescent"></ion-spinner>
        </ion-item></button>
      <button class="clear" (click)="clearSignature()">Clear</button>

    </div>
  </div>

</div>