<div class="bg-white-fa">
  <div class="box-flex">
    <div>
      <input class="basic-input-field input-bg-value" matInput (keyup)="searchComplete()" [(ngModel)]="searchVariable"
        placeholder="Search..." #input>

    </div>
    <div class="add-inventory">
      <button class="button-add-inv" (click)="openDialogadd('0ms','0ms')">Add Accessories</button>
    </div>
  </div>

  <div class="mat-elevation-z8">
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>

        <!-- Product Name Column -->
        <ng-container matColumnDef="productName" sticky>
          <th mat-header-cell *matHeaderCellDef> Product Name </th>
          <td mat-cell *matCellDef="let row">
            <button style="background: transparent;
                    " mat-icon-button aria-label="expand row" *ngIf="row.childDuplicate.length>0"
              (click)="(expandedElement === row ? null : row);">
              <!-- <mat-icon *ngIf="expandedElement !== row.childDuplicate.length>0"><span>rohit</span></mat-icon> -->

              <img style="width: 15px;
      transform: rotate(90deg);" *ngIf="expandedElement === row" src="../../../../assets/left-arrow-svgrepo-com.svg"
                alt="arrow-icon">
              <!-- <mat-icon *ngIf="expandedElement === row.childDuplicate.length > 0"><span>kapil</span></mat-icon> -->
            </button>
            {{row.productName}}
            <button style="background: transparent;
                    " mat-icon-button aria-label="expand row" *ngIf="row.childDuplicate.length>0"
              (click)="(expandedElement === row ? null : row);">
              <!-- <mat-icon *ngIf="expandedElement !== row.childDuplicate.length>0"><span>rohit</span></mat-icon> -->
              <img style="width: 15px;
                    transform: rotate(270deg);" *ngIf="expandedElement !== row"
                src="../../../../assets/left-arrow-svgrepo-com.svg" alt="arrow-icon">

              <!-- <mat-icon *ngIf="expandedElement === row.childDuplicate.length > 0"><span>kapil</span></mat-icon> -->
            </button>

          </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Type </th>
          <td mat-cell *matCellDef="let row"> {{row.type}}

          </td>
        </ng-container>

        <!-- Brand Column -->
        <ng-container matColumnDef="brand">
          <th mat-header-cell *matHeaderCellDef> Brand </th>
          <td mat-cell *matCellDef="let row"> {{row.brand}}

          </td>
        </ng-container>

        <!-- Size Column -->
        <!-- <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef > Size </th>
          <td mat-cell *matCellDef="let row"> {{row.size}} </td>
        </ng-container> -->

        <!-- Uses Column -->
        <ng-container matColumnDef="uses">
          <th mat-header-cell *matHeaderCellDef> Uses </th>
          <td mat-cell *matCellDef="let row"> {{row.uses}}

          </td>
        </ng-container>

        <!-- Material Column -->
        <ng-container matColumnDef="material">
          <th mat-header-cell *matHeaderCellDef> Material </th>
          <td mat-cell *matCellDef="let row"> {{row.material}}

          </td>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="color">
          <th mat-header-cell *matHeaderCellDef> Color </th>
          <td mat-cell *matCellDef="let row"> {{row.color}}

          </td>
        </ng-container>

        <!-- Current Quantity Column -->
        <ng-container matColumnDef="currentQuantity">
          <th mat-header-cell *matHeaderCellDef> Current Quantity </th>
          <td mat-cell *matCellDef="let row"> {{row.currentQuantity}}

          </td>
        </ng-container>

        <!-- Recorder Level Column -->
        <ng-container matColumnDef="reorderLevel">
          <th mat-header-cell *matHeaderCellDef> Reorder Level </th>
          <td mat-cell *matCellDef="let row"> {{row.reorderLevel}}

          </td>
        </ng-container>

        <!-- Re-Qua.. Column -->
        <ng-container matColumnDef="reQuantity">
          <th mat-header-cell *matHeaderCellDef> Re-Qua... </th>
          <td mat-cell *matCellDef="let row"> {{row.reQuantity}}

          </td>
        </ng-container>

        <!-- Purchase P... Column -->
        <ng-container matColumnDef="purchasePrice">
          <th mat-header-cell *matHeaderCellDef> Purchase P... </th>
          <td mat-cell *matCellDef="let row"> {{row.purchasePrice}}

          </td>
        </ng-container>

        <!-- Retail Price Column -->
        <ng-container matColumnDef="retailprice">
          <th mat-header-cell *matHeaderCellDef> Retail Price </th>
          <td mat-cell *matCellDef="let row"> {{row.retailprice}}

          </td>
        </ng-container>

        <!-- Max Retail Dis... Column -->
        <ng-container matColumnDef="maxRetailDiscount">
          <th mat-header-cell *matHeaderCellDef> Max Retail Discount </th>
          <td mat-cell *matCellDef="let row"> {{row.maxRetailDiscount}}

          </td>
        </ng-container>

        <!-- Barcode/SKU Column -->
        <ng-container matColumnDef="barCode">
          <th mat-header-cell *matHeaderCellDef> Barcode/SKU </th>
          <td mat-cell *matCellDef="let row"> {{row.barCode}}

          </td>
        </ng-container>

        <!-- Location Column -->
        <ng-container matColumnDef="Location">
          <th mat-header-cell *matHeaderCellDef> Location </th>
          <td mat-cell *matCellDef="let row"> {{row.location}}

          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action" stickyEnd>
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let row" class="actionbtn two-icons"><ion-button
              (click)="openDialog('0ms', '0ms',row._id)"><img
                src="../../../assets/ruler&pen.svg"></ion-button><ion-button (click)="deletebyid(row._id)"> <img
                src="../../../assets/Trash.svg"></ion-button>
            <ion-button (click)="AddNew(row._id)">
              <img src="../../../assets/plusIcon.svg">
            </ion-button>
          </td>
        </ng-container>

        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="display: none;"> </th>
          <td mat-cell *matCellDef="let element" style="display: none;">
            <button mat-icon-button aria-label="expand row"
              (click)="(expandedElement = expandedElement === element.childDuplicate.length>0 ? null : element.childDuplicate.length>0); $event.stopPropagation()">
              <mat-icon *ngIf="expandedElement !== element.childDuplicate.length>0"><span>rohit</span></mat-icon>

              <mat-icon *ngIf="expandedElement === element.childDuplicate.length > 0"><span>kapil</span></mat-icon>
            </button>
          </td>
        </ng-container>


        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

              <table>
                <tbody>

                  <tr *ngFor="let data of element?.childDuplicate;let i =index;">
                    <td>{{data?.productName}}</td>
                    <td>{{data?.type}}</td>
                    <td>{{data?.brand}}</td>
                    <td>{{data?.uses}}</td>
                    <td>{{data?.material}}</td>
                    <td>{{data?.color}}</td>
                    <td>{{data?.currentQuantity}}</td>

                    <td>{{data?.reorderLevel}}</td>
                    <td>{{data?.reQuantity}}</td>

                    <td>{{data?.purchasePrice}}</td>
                    <td>{{data?.retailprice}}</td>
                    <td>{{data?.maxRetailDiscount}}</td>
                    <td>{{data?.barCode}}</td>

                    <td>{{data?.location}}</td>

                    <td stickyEnd>
                      <ion-button (click)="openDialog('0ms', '0ms',data._id)"><img
                          src="../../../assets/ruler&pen.svg"></ion-button><ion-button (click)="deletebyid(data._id)">
                        <img src="../../../assets/Trash.svg"></ion-button>
                      <!-- <ion-button (click)="AddNew(data._id)">
                        <img src="../../../assets/plusIcon.svg">
                      </ion-button> -->

                    </td>

                  </tr>
                </tbody>
              </table>

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="10" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons
      #paginator>
    </mat-paginator>
  </div>
</div>