import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import SignaturePad from "signature_pad";
import { CrudService } from 'src/app/services/crud/crud.service';
import { FileUploadService } from 'src/app/services/fileupload/file-upload.service';
import { FileUpload } from 'src/app/shared/classes/file-upload';
@Component({
  selector: 'app-prescription-image',
  templateUrl: './prescription-image.component.html',
  styleUrls: ['./prescription-image.component.scss'],
  host: {
    '(keyup.ctrl.k)': 'clear()'
  }
})
export class PrescriptionImageComponent implements OnInit {
  @ViewChild("canvas34", { static: true }) canvas34: ElementRef;
  sig: SignaturePad;
  prescriptionImage: any;
  currentUpload: FileUpload;
  userProfile: any = localStorage.getItem('userProfile');
  spinner:boolean=false
  imageEdit:any
  constructor(public dialogRef: MatDialogRef<PrescriptionImageComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private CrudService: CrudService,
    private FileUploadService: FileUploadService 

    ) {
      this.userProfile = JSON.parse(this.userProfile);
    

     }
    clear() {
      this.sig.clear();
    }
  ngOnInit() {
    this.sig = new SignaturePad(this.canvas34.nativeElement);

  }
  closedialog(){
    this.dialogRef.close();
  }
  saveSignature() {
    this.spinner = true
    let can: any = document.getElementById('canvas34');
    var img = new Image();
    
    img.src = can.toDataURL();
    console.log(img.src,'hhhh',can)
    
 
   let data : any =  this.DataURIToBlob(img.src)
    console.log(data , "hahahaha",img.src)
    
    this.prescriptionImage = img.src
    console.log(img.src, "img.src")
    this.currentUpload = data;
    console.log(this.currentUpload,'this.currentUpload')

    this.FileUploadService.pushUpload(this.currentUpload, '/appointment/updatePresImage/' + this.userProfile?._id).then((res: any) => {
        let data = {
          "prescriptionImage":res.url,
          "branchId": this.CrudService?.branchId,
          "clientId": this.userProfile?.clientId?._id,
  
        }
      //  return
        this.CrudService.edit(data,this.data?.id,'appointment/updatePresImage').then((res:any)=>{

          this.closedialog()
          console.log(res.url, 'file')
          let can: any = document.getElementById('canvas34');
          let blank = can.value=''
        })


      });
  }
  clearSignature(){
    this.sig.clear();
  }
  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        console.log("byteString",byteString , "byteString")
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)
      let a : any = new Blob([ia], { type: mimeString })
      console.log(a , "hehehe")
        return new Blob([ia], { type: mimeString })
}
openprescriptionedit(){
  this.data.title = 'add'
}


}
