import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {
  userprofile :any;
  userProfile :any;

  constructor(public route:Router) {  }
  canActivate(
   
  ):any{

    if(localStorage.getItem("token")){
       return true;
    }
  else{
    this.route.navigate([localStorage.getItem('clinic')]);
    return false;
  }
}


}
