import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-add-inventory',
  templateUrl: './add-inventory.component.html',
  styleUrls: ['./add-inventory.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class AddInventoryComponent implements OnInit {
  addForm: any
  liquids:boolean=false
  tab:any
  userProfile: any = localStorage.getItem('userProfile');

  constructor(
    public dialogRef: MatDialogRef<AddInventoryComponent>,
    private toast: ToastComponent,
    private crudservice: CrudService
  ) {
    console.log(this.tab)

    this.userProfile = JSON.parse(this.userProfile);
          var barCode = Math.floor(100000000 + Math.random() * 900000000);
          
      console.log(barCode,'barCode')

    this.addForm = {
      productName: "",
      Brand: "",
      DosageFom: "",
      Strength: "",
      Strength2:"",
      currentQuantity: "",
      ReOrderLevel: "",
      ReQuantity: "",
      SupplierInfo: "",
      ExpirationDate: "",
      BatchNo: "",
      StorageTemperature: "",
      IndicationForUse: "",
      ContraIndication: "",
      SideEffects: "",
      PurchasePrice: "",
      RetailPrice: "",
      reataildisc: "",
      barCode: "",
      maxRetailDiscount: "",
      Location: "",
      HSN: "",
      SGST: "",
      CGST: "",
      Inventorytype: "Medicine",
      clientId: this.userProfile?.clientId?._id,
      branchId: this.crudservice?.branchId,
      // medicineId:"",
      notes:"",
      freeSamples:"",
      addQuantity:""

  }
  this.addForm.barCode = barCode
  
}

  ngOnInit() { }
  closedialog() {
    this.dialogRef.close();
  }
  liquid(event:any){
    if (event == 'Tablets') {
      this.tab = 'mg/tab';

    }
    if (event == 'Capsules') {
      this.tab = 'mg/cap'

    }
    if (event == 'Liquids' || event == 'Suspensions' || event == 'Injections') {
      this.tab = 'mg/ml'

    }
    console.log(this.addForm.DosageFom,'ssss')
    if(event == 'Liquids' || event == 'Suspensions'){
      this.liquids = true;

    } else {
      this.liquids = false;
    }
    console.log(event,'eent')

  }

  save() {
    console.log(this.addForm,'bracode')

    this.crudservice.add(this.addForm, environment.api.medicine).then((res) => {
      this.toast.presentToast("New Medicine Added")
      this.dialogRef.close()
      this.addForm.reset()

    })



  }
}

