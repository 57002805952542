import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
// import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {

  constructor(public toastController: ToastController,
    ) {}

  async presentToastInfo(toastMessage:string) {
    const toast = await this.toastController.create({
      animated: true,
      message: toastMessage,
      position:"top",
      duration: 3000,
      cssClass : "toastMessage",
      keyboardClose: true,
    });
    toast.present();
  }

  presentToast(toastMessage:string) {
    this.presentToastInfo(toastMessage);

  }
  presentToastDemo(toastMessage:string,css:any) {
    this.presentToastInfo(toastMessage);

  }

  presentToastError(toastMessage:string) {
    this.presentToastInfo(toastMessage);


  }

  ngOnInit() {}

}
