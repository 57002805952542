import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { DialogboxComponent } from 'src/app/components/dialogbox/dialogbox.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
routePath:any;
slug:any=localStorage.getItem('clinic')
userProfile:any = localStorage.getItem('userProfile')
  constructor(public router :Router,
    public dialog: MatDialog,
    ) { 
      this.userProfile = JSON.parse(this.userProfile); 
   
  }

  ngOnInit() {

    if(this.router.url === '/'+this.slug+'/main/admin-staff' || this.router.url === '/'+this.slug+'/main/admin-table' ||  this.router.url === '/'+this.slug+'/main/dashboard' || this.router.url === '/'+this.slug+'/main/supplier' || this.router.url === '/'+this.slug+'/main/service' || this.router.url === '/'+this.slug+'/main/branches' || this.router.url == '/'+this.slug+'/manager-main/consultation'
    || this.router.url == '/'+this.slug+'/manager-main/customer-lookup' || this.router.url === '/'+this.slug+'/manager-main/roles-users' || this.router.url === '/'+this.slug+'/main/admin-sales-transactions' || this.router.url === '/'+this.slug+'/main/admin-table' || 
    this.router.url == '/'+this.slug+'/manager-main/manage-users' || this.router.url == '/'+this.slug+'/manager-main/follow-up')
    {
      this.routePath = this.router.url;
      console.log(this.routePath,'routePath')
    }
  
  }
  back(data:any){
    if(data == 'all'){

      this.router.navigate([localStorage.getItem('clinic')+"/main/dashboard"])
    }
    if(data == 'roles-users'){

      this.router.navigate([localStorage.getItem('clinic')+"/manager-main/roles-users"])
    }

  }
  

}
