<div>
  <p>Creating Branch</p>
  <mat-icon aria-hidden="false" aria-label="Example exit icon" fontIcon="exit"></mat-icon>
</div>

<div>
  <mat-form-field>
    <mat-label>Branch Name</mat-label>
    <input matInput type="text" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Primary Contact</mat-label>
    <input matInput type="tel" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Secondary Contact</mat-label>
    <input matInput type="tel" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Opens at</mat-label>
    <input matInput type="time" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Close at</mat-label>
    <input matInput type="time" />
  </mat-form-field>
</div>

<div>
  <p>This is fantastic, All the best in expanding</p>
  <button mat-button>Cancel</button>
  <button mat-button>Create</button>
</div>