import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <== add the imports!
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { AdminFoodComponent } from '../pages/admin-table/admin-food/admin-food.component';
import { AdminAccesoriesComponent } from '../pages/admin-table/admin-accesories/admin-accesories.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BackgroundPortionComponent } from './background-portion/background-portion.component';
import { NewBranchComponent } from './new-branch/new-branch.component';
import { NewUserComponent } from './new-user/new-user.component';
import { DialogboxComponent } from './dialogbox/dialogbox.component';
import { InventoryEditComponent } from './inventory-edit/inventory-edit.component';
import { FoodEditComponent } from './food-edit/food-edit.component';
import { AccessoriesEditComponent } from './accessories-edit/accessories-edit.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AddInventoryComponent } from './add-inventory/add-inventory.component';
import { AddFoodComponent } from './add-food/add-food.component';
import { AddAccessoriesComponent } from './add-accessories/add-accessories.component';
import { UserAddComponent } from './user-add/user-add.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { CustomerLookupPage } from '../pages/manager/customer-lookup/customer-lookup.page';
import { BillingComponent } from './billing/billing.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PrescriptionImageComponent } from './prescription-image/prescription-image.component';
import { HistoryPrescriptionComponent } from './history-prescription/history-prescription.component';
import { WebcamComponentComponent } from './webcam-component/webcam-component.component';
import {WebcamModule} from 'ngx-webcam';
@NgModule({
  // declarations: [DialogboxComponent,HeaderComponent,AdminFoodComponent,AdminAccesoriesComponent, SidebarComponent,BackgroundPortionComponent,InventoryEditComponent,FoodEditComponent,AccessoriesEditComponent],
  // exports:[HeaderComponent,AdminFoodComponent,AdminAccesoriesComponent, SidebarComponent,BackgroundPortionComponent,DialogboxComponent],
  // imports: [CommonModule,IonicModule,MatTableModule,MatPaginatorModule,MatInputModule,MatFormFieldModule,MatSortModule, MatSelectModule,MatDialogModule,FormsModule,MatDatepickerModule,MatNativeDateModule],
  // providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },HeaderComponent,MatDatepickerModule],
  declarations: [DialogboxComponent, HeaderComponent, AdminFoodComponent,UserAddComponent, AdminAccesoriesComponent, SidebarComponent, BackgroundPortionComponent, InventoryEditComponent, FoodEditComponent, AccessoriesEditComponent, NewBranchComponent, NewUserComponent,AddInventoryComponent,AddFoodComponent,AddAccessoriesComponent,BillingComponent,PrescriptionImageComponent,HistoryPrescriptionComponent,WebcamComponentComponent],
  exports: [HeaderComponent, AdminFoodComponent, AdminAccesoriesComponent,UserAddComponent, SidebarComponent, BackgroundPortionComponent, DialogboxComponent, NewBranchComponent, NewUserComponent],
  imports: [CommonModule,WebcamModule,MatSlideToggleModule, IonicModule, MatTableModule, MatPaginatorModule, MatInputModule, MatFormFieldModule, MatSortModule, MatSelectModule, MatDialogModule, FormsModule, MatDatepickerModule, MatNativeDateModule, MatIconModule,NgxSkeletonLoaderModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, HeaderComponent,CustomerLookupPage],
  bootstrap: [],
  entryComponents: [HeaderComponent, AdminFoodComponent, SidebarComponent, BackgroundPortionComponent]
})
export class componentModule { }
