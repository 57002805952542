import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ToastComponent } from './shared/toast/toast.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ManagerMainPageModule } from './pages/manager-main/manager-main.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import {MatIconModule} from '@angular/material/icon';
import { ServiceWorkerModule } from '@angular/service-worker';

// import { DialogboxComponent } from './pages/dialogbox/dialogbox.component';
const config: SocketIoConfig = {
	url: 'https://dhor.clinic.api.stageprojects.xyz/', // socket server url;
	options: {
   transports: ["polling"],

		// transports: ['websocket']
	}
}
@NgModule({
  declarations: [AppComponent, ToastComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ animated: false }),
    AppRoutingModule,
    HttpClientModule,
    MatFormFieldModule,
    MatSelectModule,
    ManagerMainPageModule,
    MatIconModule,
    SocketIoModule.forRoot(config),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // SocketIoModule.forRoot(config)
  ],
  providers: [
    { provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    // AuthguardGuard,
    ToastComponent],

   
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
