<div class="inventory-edit">
  <div class="center-modal-header">

    <div>
      <h1>Add Accessory</h1>

    </div>
    <div style="padding-top: 6px;
  padding-right: 7px;">
      <button class="close-button" (click)="closedialog()"><img src="../assets/close-icon.svg"></button>
    </div>
  </div>

</div>

<div>
  <form class="example-form" style="max-width: 100%;" #Form="ngForm">
    <div style="display: flex;">
      <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
        <mat-label>Product</mat-label>
        <input matInput type="text" placeholder="Name" required [(ngModel)]="addForm.productName"
          name="addForm.productName" />
      </mat-form-field>

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Type</mat-label>
        <mat-select [(ngModel)]="addForm.type" name="addForm.type" placeholder="Select">
          <mat-option value="Chew toys">Chew toys</mat-option>
          <mat-option value="Leashes and collars">Leashes and collars</mat-option>
          <mat-option value="Beds">Beds</mat-option>
          <mat-option value="Crates and carriers">Crates and carriers</mat-option>
          <mat-option value="Grooming tools">Grooming tools</mat-option>
          <mat-option value="Treats">Treats</mat-option>
          <mat-option value="Clothing and costumes">Clothing and costumes</mat-option>
          <mat-option value="Feeding bowls and dishes">Feeding bowls and dishes</mat-option>
          <mat-option value="Toys and balls">Toys and balls</mat-option>
          <mat-option value="Scratching posts and pads">Scratching posts and pads</mat-option>
        </mat-select>
        <!-- <input matInput placeholder="Type" [(ngModel)]="addForm.type" name="addForm.type"> -->
      </mat-form-field>

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Brand</mat-label>
        <input matInput placeholder="Name" [(ngModel)]="addForm.brand" name="addForm.brand">
      </mat-form-field>
    </div>
    <div style="display: flex;">
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Uses</mat-label>
        <input matInput placeholder="How to" [(ngModel)]="addForm.uses" name="addForm.uses">
      </mat-form-field>

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Material</mat-label>
        <mat-select [(ngModel)]="addForm.material" name="addForm.material" placeholder="Select">
          <mat-option value="Rubber">Rubber</mat-option>
          <mat-option value="Plastic">Plastic</mat-option>
          <mat-option value="Nylon">Nylon</mat-option>
          <mat-option value="Rope">Rope</mat-option>
          <mat-option value="Leather">Leather</mat-option>
          <mat-option value="Fleece">Fleece</mat-option>
          <mat-option value="Cotton">Cotton</mat-option>
          <mat-option value="Sisal">Sisal</mat-option>
          <mat-option value="Wood">Wood</mat-option>

        </mat-select>
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Color</mat-label>
        <mat-select [(ngModel)]="addForm.color" name="addForm.color" placeholder="Select">
          <mat-option value="Red">Red</mat-option>
          <mat-option value="Orange">Orange</mat-option>
          <mat-option value="Red">Red</mat-option>
          <mat-option value="Yellow">Yellow</mat-option>
          <mat-option value="Green">Green</mat-option>
          <mat-option value="Blue">Blue</mat-option>
          <mat-option value="Purple">Purple</mat-option>
          <mat-option value="Pink">Pink</mat-option>
          <mat-option value="Brown">Brown</mat-option>
          <mat-option value="Black">Black</mat-option>
          <mat-option value="White">White</mat-option>

        </mat-select>
        <!-- <input matInput placeholder="Color" [(ngModel)]="addForm.color" name="addForm.color"> -->
      </mat-form-field>
    </div>
    <div style="display: flex;">
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Current Quantity
        </mat-label>
        <input matInput placeholder="No's" [(ngModel)]="addForm.currentQuantity" name="addForm.currentQuantity">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Reorder Level

        </mat-label>
        <input matInput placeholder="No's" [(ngModel)]="addForm.reorderLevel" name="addForm.reorderLevel">
      </mat-form-field>

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Reorder Quantity

        </mat-label>
        <input matInput placeholder="No's" [(ngModel)]="addForm.reQuantity" name="addForm.reQuantity">
      </mat-form-field>
    </div>

    <div style="display: flex;">
      <mat-form-field class="example-full-width mando">
        <mat-label>Purchase Price</mat-label>
        <input matInput placeholder="Rs" [(ngModel)]="addForm.purchasePrice" name="addForm.purchasePrice" required="">
      </mat-form-field>

      <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
        <mat-label>Retail Price</mat-label>
        <input matInput placeholder="Rs" [(ngModel)]="addForm.retailPrice" name="addForm.retailPrice" required="">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Max Retail Discount</mat-label>
        <input matInput placeholder="Rs" [(ngModel)]="addForm.maxRetailDiscount" name="addForm.maxRetailDiscount">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>HSN Code</mat-label>
        <input matInput placeholder="HSN Code" [(ngModel)]="addForm.HSN" name="addForm.HSN">
      </mat-form-field>

    </div>
    <div style="display: flex;">
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>SGST</mat-label>
        <input matInput placeholder="SGST" [(ngModel)]="addForm.SGST" name="addForm.SGST">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>CGST</mat-label>
        <input matInput placeholder="CGST" [(ngModel)]="addForm.CGST" name="addForm.CGST">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Barcode/SKU</mat-label>
        <input matInput placeholder="No." readonly [(ngModel)]="addForm.barCode" name="addForm.barCode">
      </mat-form-field>

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Location
        </mat-label>
        <input matInput placeholder="Cabin etc" [(ngModel)]="addForm.location" name="addForm.location">
      </mat-form-field>
    </div>

  </form>
  <div class="modalFooter">
    <p>
      All set to add this Accesory? Just give it one final check
    </p>
    <div>
      <button class="modalSecondaryBt" type="submit" (click)="closedialog()">Cancel</button>
      <button class="modalPrimaryBt" type="submit" (click)="save()" [disabled]="Form.invalid">Create
        Accesory</button>
    </div>
  </div>
</div>