import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CrudService } from 'src/app/services/crud/crud.service';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-view-detailed-log',
  templateUrl: './view-detailed-log.component.html',
  styleUrls: ['./view-detailed-log.component.scss'],
})
export class ViewDetailedLogComponent implements OnInit {
  treatmentData: any = []
  drugData: any = [];
  p: any;
  bottles: any
  ids: any;
  viewDetailslogs: any
  viewDetailslogPrevious: any;
  viewDetailslogSession: any;
  sessionAppointId: any = [];
  skeleton: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<ViewDetailedLogComponent>,
    public http: HttpClient,

    private CrudService: CrudService
  ) {
    setTimeout(() => {
      this.skeleton = false;
    }, 2000);
    console.log(this.ids, 'ids', '')
    if (this.data?.dogId) {
      this.CrudService.getbyid(this.data?.dogId, 'appointment/getDogLogs').then((res: any) => {
        this.viewDetailslogs = res;

        console.log(this.viewDetailslogs, 'viewdetails');


      })
    }
    this.viewDetails()

  }

  ngOnInit() {
    this.setChildHeight();
  }
  viewDetails() {
    this.treatmentData = []
    console.log(this.data?.id, 'ids')
    this.CrudService.getbyid(this.data?.id, environment.api.appoinment).then((res: any) => {
      if (res == '' || res == null) {
        this.CrudService.getbyid(this.data?.id, 'session/getAllDataSession').then((res: any) => {
          this.treatmentData = res[0]
          this.treatmentData.weight = this.treatmentData?.weight?.slice(0, 3)
          this.treatmentData.temperature = this.treatmentData?.temperature?.slice(0, 3)
          this.treatmentData.heartRate = this.treatmentData?.heartRate?.slice(0, 3)
          console.log(this.treatmentData.weight, res, "data56556")

          let filter = {
            where: {
              sessionId: this.treatmentData?._id,

            }
          }
          this.CrudService.get('drugPrescribe', filter).then((res: any) => {
            this.drugData = res
            console.log(this.drugData, 'drugData')

          })
          this.CrudService.getbyid(this.treatmentData?.appointmentId?._id, 'appoinment').then((res: any) => {
            this.viewDetailslogSession = []

            this.viewDetailslogSession.push(res);
            this.sessionAppointId = []
            this.viewDetailslogSession?.map((item1: any) => {
              this.sessionAppointId.push(item1?._id)
            })
            console.log('datatattata', this.sessionAppointId, this.treatmentData?._id)


            if (this.sessionAppointId.includes(this.treatmentData?.appointmentId?._id)) {

              this.viewDetailslogSession.push(this.treatmentData);

            }

            console.log(this.viewDetailslogSession, 'viewdetails')
          })

        })

      } else {
        this.treatmentData = res


        this.treatmentData.weight = this.treatmentData?.weight?.slice(0, 3)
        this.treatmentData.temperature = this.treatmentData?.temperature?.slice(0, 3)
        this.treatmentData.heartRate = this.treatmentData?.heartRate?.slice(0, 3)
        console.log(this.treatmentData.weight, res, "data56556")
        let filter = {
          where: {
            appointmentId: res?._id,

          }
        }
        this.CrudService.get('drugPrescribe', filter).then((res: any) => {
          this.drugData = res
          const keys = ['bottle'];

          this.bottles = this.drugData.filter((value: any, index: any, self: any) =>
            self.findIndex((v: any) => keys.every(k => v[k] === value[k])) === index
          );
          console.log(this.bottles,'this.bottles')


        })

        this.CrudService.getbyid(this.treatmentData?.dogs?._id, 'session/getDogLogs').then((res: any) => {
          this.viewDetailslogSession = res;
          this.sessionAppointId = []
          this.viewDetailslogSession?.map((item1: any) => {
            this.sessionAppointId.push(item1?.appointmentId)
          })
          console.log('datatattata', this.sessionAppointId, this.treatmentData)


          if (this.sessionAppointId.includes(this.treatmentData?._id)) {
            this.viewDetailslogSession.push(this.treatmentData);

          }


          console.log(this.viewDetailslogSession, 'viewdetails')
        })
      }

    })

  }
  closedialog() {
    this.dialogRef.close();
  }
  setChildHeight() {
    const parent = document.querySelector('.parent') as HTMLElement;
    if (parent) {
      const child = parent.querySelector('.child') as HTMLElement;
      const childHeight = parent.offsetHeight - 50; // subtract 50px
      if (child) {
        child.style.height = childHeight + 'px';
      }
    }
  }






  page(event: any, id: any) {
    console.log(event, id, 'event')
    this.p = event
    this.treatmentData = []
    this.CrudService.getbyid(id, environment.api.appoinment).then((res: any) => {
      if (res == '' || res == null) {
        this.CrudService.getbyid(id, 'session/getAllDataSession').then((res: any) => {

          this.treatmentData = res[0]
          this.treatmentData.weight = this.treatmentData?.weight?.slice(0, 3)
          this.treatmentData.temperature = this.treatmentData?.temperature?.slice(0, 3)
          this.treatmentData.heartRate = this.treatmentData?.heartRate?.slice(0, 3)
          console.log(this.treatmentData.weight, res, "data56556")
        })
      } else {
        this.treatmentData = res
        this.treatmentData.weight = this.treatmentData?.weight?.slice(0, 3)
        this.treatmentData.temperature = this.treatmentData?.temperature?.slice(0, 3)
        this.treatmentData.heartRate = this.treatmentData?.heartRate?.slice(0, 3)
        console.log(this.treatmentData.weight, res, "data56556")
      }


    })
  }
  downloadFile(data: any): any {
    return this.http.get(data, { responseType: 'blob' });
  }


  download(data2: any, file: any) {

    console.log(data2, 'data7689i');
    this.downloadFile(data2).subscribe((response: any) => {
      console.log(response, 'resp3223')
      // let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      // const url = window.URL.createObjectURL(blob);

      const blob = new Blob([response], { type: 'text/json; charset=utf-8' });
      // const url = window.URL.createObjectURL(blob);
      // window.open(url, '_blank');



      saveAs(blob, file + '.pdf');
    }), (error: any) => console.log('Error downloading the file'),
      () => console.log('File downloaded successfully');
  }

  openPdfFile(data2: any) {
    this.downloadFile(data2).subscribe((response: any) => {
      let win: any = window.open('_blank');
      let blobb = new Blob([response], { type: 'application/pdf' });
      let url: any = URL.createObjectURL(blobb);
      win.location.href = url;
    })
  }


}

