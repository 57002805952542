<div *ngIf="routePath == '/'+slug+'/main/admin-staff'" class="page-title">
  <h3>
    Staffs : {{userProfile?.name}} ({{userProfile?.role?.name}})
  </h3>
</div>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/admin-table'">
  Inventory : {{userProfile?.name}} ({{userProfile?.role?.name}})
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/dashboard'">
  Dashboard : {{userProfile?.name}} ({{userProfile?.role?.name}})
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/supplier'">
  Suppliers : {{userProfile?.name}} ({{userProfile?.role?.name}})
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/service'">
  Services : {{userProfile?.name}} ({{userProfile?.role?.name}})
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/branches'">
  Branches : {{userProfile?.name}} ({{userProfile?.role?.name}})
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/manager-main/consultation'">
  <button class="back-buttons-header" style="background-color: transparent;" (click)="back('all')">
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.135 13.7588H5.875" stroke="#121212" stroke-width="2.04375" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.505 21.3885L5.875 13.7584L13.505 6.12842" stroke="#121212" stroke-width="2.04375" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    <!-- <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img> -->
  </button>
  Consultation : {{userProfile?.name}} ({{userProfile?.role?.name}})
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/manager-main/customer-lookup'">
  Patient Lookup : {{userProfile?.name}} ({{userProfile?.role?.name}})
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/manager-main/roles-users'">
  Role & Users : {{userProfile?.name}} ({{userProfile?.role?.name}})
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/admin-sales-transactions'">
  <button style="background-color: transparent;" (click)="back('all')">
    <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img>
  </button>
  Sales
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/main/admin-table'">
  <button style="background-color: transparent;" (click)="back('all')">
    <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img>
  </button>
  Inventory : {{userProfile?.name}} ({{userProfile?.role?.name}})
</p>
<p class="sticky-Body-Nav heading-text-value" *ngIf="routePath == '/'+slug+'/manager-main/manage-users'">
  <button style="background-color: transparent;" (click)="back('roles-users')">
    <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img>
  </button>
  Manage Users : {{userProfile?.name}} ({{userProfile?.role?.name}})
</p>
<p class="sticky-Body-Nav" *ngIf="routePath == '/'+slug+'/manager-main/follow-up'">
  <button style="background-color: transparent;" (click)="back('all')">
    <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img>
  </button>
  Follow Up : {{userProfile?.name}} ({{userProfile?.role?.name}})
</p>
