import { Component } from '@angular/core';
import { CrudService } from './services/crud/crud.service';
import { environment } from 'src/environments/environment';
import { Title } from "@angular/platform-browser";
import { Socket } from 'ngx-socket-io';
import { LoadingserviceService } from './services/loading/loadingservice.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userProfile: any = localStorage.getItem('userProfile');
  favIcon: any;
  siteUrl:any
  loadingFlag: boolean = false;
  lottieConfig: any;
  anim: any;

  
  constructor(private crudService: CrudService,
    public loader: LoadingserviceService,
    private Title: Title , private socket : Socket) {
      setTimeout(() => {
        this.loadingFlag = true;
      }, 2000);
      this.lottieConfig = {
        path: 'assets/loading.json',
        renderer: 'canvas',
        autoplay: true,
        loop: true
      };
    let icon:any = document.querySelector('#appIcon')
    setTimeout(() => {
      
      console.log(this.socket.connect() , "this.socket.connect()")
    }, 5000);
    if(this.userProfile?.clientId?._id){
    if(icon){
      this.favIcon = icon
    }
        // this.socket.connect();
        console.log('connect')

    this.userProfile = JSON.parse(this.userProfile);
    console.log(this.userProfile, 'this.userProfile')
    this.crudService.getbyid(this.userProfile?.clientId?._id, environment.api.client).then((resp: any) => {
      if(this.favIcon){

        this.favIcon.href = resp[0]?.image;
      }
      this.Title.setTitle(resp[0]?.siteTitle)
      // this.favicon = 
      
      // console.log(this.favicon,'href')
    })

    if (localStorage.getItem("branchId")) {
      this.crudService.branchId = localStorage.getItem("branchId");
      console.log(this.crudService.branchId, 'branchid')

    }
  }
}
animationCreated(anim: any) {
  this.anim = anim;
}

}
