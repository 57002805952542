import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CrudService } from 'src/app/services/crud/crud.service';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { environment } from 'src/environments/environment';
import { MY_DATE_FORMATS } from '../inventory-edit/inventory-edit.component';

@Component({
  selector: 'app-dialogbox',
  templateUrl: './dialogbox.component.html',
  styleUrls: ['./dialogbox.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class DialogboxComponent implements OnInit {
  addForm: any;
  addFood: any;
  liquids: boolean = false
  addAccess:any
  userProfile: any = localStorage.getItem('userProfile');
  
  constructor(public dialogRef: MatDialogRef<DialogboxComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private toast: ToastComponent,
    private crudservice: CrudService
  ) {
    console.log(this.data, 'console')
    this.userProfile = JSON.parse(this.userProfile);
    this.crudservice.branchId = localStorage.getItem('branchId')

    if (this.data?.title == 'AddNewInventory') {
      this.addForm = {
        productName: "",
        Brand: "",
        DosageFom: "",
        Strength: "",
        Strength2: "",
        CurrentQuantity: "",
        ReOrderLevel: "",
        ReQuantity: "",
        SupplierInfo: "",
        ExpirationDate: "",
        BatchNo: "",
        StorageTemperature: "",
        IndicationForUse: "",
        ContraIndication: "",
        SideEffects: "",
        PurchasePrice: "",
        RetailPrice: "",
        reataildisc: "",
        BarCode: "",
        maxRetailDiscount: "",
        Location: "",
        HSN: "",
        SGST: "",
        CGST: "",
        Inventorytype: "Medicine",
        clientId: this.userProfile?.clientId?._id,
        branchId: this.crudservice?.branchId,
        medicineId: this.data?.id,
        notes: "",
        freeSamples: "",
        addQuantity: ""



      }
      this.crudservice.getbyid(this.data?.id, environment.api.medicine).then((res: any) => {
        this.addForm = res;
      })
    }
    if (this.data?.title == 'AddNewFood') {
      this.addFood = {
        productName: "",
        brand: "",
        weight: "",
        strength: "",
        lifeStage: "",
        nutritionalInformation: "",
        ingredients: "",
        type: "",
        HSN: "",
        SGST: "",
        CGST: "",
        Inventorytype: "Food",

        proteinSource: "",
        flavour: "",
        currentQuantity: "",
        reorderLevel: "",
        reQuantity: "",
        batchNo: "",
        purchasePrice: "",
        retailPrice: "",
        maxRetailDiscount: "",
        barCode: "",
        location: "",
        clientId: this.userProfile?.clientId?._id,
        branchId: this.crudservice?.branchId,
        foodId: this.data?.id,
        notes: "",
        freeSamples: "",
        addQuantity: ""
      }
      this.crudservice.getbyid(this.data?.id, environment.api.food).then((res: any) => {
        this.addFood = res;
      })
    }
    if (this.data?.title == 'AddNewAccessories') {
      this.addAccess = {
        productName: "",
        brand: "",
        type: "",
        uses: "",
        currentQuantity: "",
        reorderLevel: "",
        reQuantity: "",
        material: "",
        color: "",
        purchasePrice: "",
        retailPrice: "",
        maxRetailDiscount: "",
        barCode: "",
        location: "",
        HSN:"",
        SGST:"",
        CGST:"",
        Inventorytype:"Accessories",
        clientId: this.userProfile?.clientId?._id,
        branchId: this.crudservice?.branchId,
        accessoriesId:this.data?.id,
        notes: "",
        freeSamples: "",
        addQuantity: ""
      }
      this.crudservice.getbyid(this.data?.id, environment.api.accessories).then((res: any) => {
        this.addAccess = res;
      })
    }
    
  }


  ngOnInit() { }

  diaclose() {
    this.dialogRef.close();

  }
  closedialog() {
    this.dialogRef.close();
  }
  liquid(event: any) {
    if (event == 'Liquids' || event == 'Suspensions') {
      this.liquids = true;

    } else {
      this.liquids = false;
    }
    console.log(event, 'eent')

  }

  save() {
    delete this.addForm._id
    this.addForm.medicineId = this.data?.id
    this.crudservice.add(this.addForm, environment.api.medicine).then((res) => {
      this.toast.presentToast("New Medicine Added")
      this.dialogRef.close()

    })



  }
  saveFood() {
    delete this.addFood._id
    this.addFood.foodId = this.data?.id
    this.crudservice.add(this.addFood, environment.api.food).then((res) => {
      this.toast.presentToast("New Food Added")
      this.dialogRef.close()

    })



  }
  saveAccess(){
    delete this.addAccess._id
    this.addAccess.accessoriesId = this.data?.id
    this.crudservice.add(this.addAccess, environment.api.accessories).then((res) => {
      this.toast.presentToast("New Accessories Added")
      this.dialogRef.close()

    })

  }
}
