import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss'],
})
export class UserAddComponent implements OnInit {

  detail:boolean=false;
  value : any=true;

  constructor() { }

  ngOnInit() {}

  advance(event: any) {
    this.detail = true
    this.value = false;
    if (event == false) {
      this.detail = false;
      this.value = true;
    }
  }

}
