import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-background-portion',
  templateUrl: './background-portion.component.html',
  styleUrls: ['./background-portion.component.scss'],
})
export class BackgroundPortionComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
