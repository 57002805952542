<div class="main">
    <div class="back-box">

        <div class="img-box">
            <!-- <img class="arrow-icon-value" (click)="back()"   routerDirection="back"src="../../../assets/Frontdesk-images/arrow-left1.png"> -->

            <p class="parent-text">Creating Parent Account</p>
        </div>
        <div class="toggle-btn-box">
            <span><mat-slide-toggle class="toggle-btn-value" (click)="advance(value)">Mandatory
                    Only</mat-slide-toggle></span>
        </div>
    </div>
    <div class="form-inner-box">



        <div class="form-bg-box">
            <form>
                <div class="full-input">


                    <mat-form-field class="input-box-value mando" appearance="outline">
                        <mat-label>Parent Name</mat-label>
                        <input matInput placeholder="Parent Name">
                    </mat-form-field>
                </div>
                <div class="mat-bg-box">
                    <mat-form-field class="parent-input-btn  input-box-value ">
                        <mat-label>Parent Phone</mat-label>
                        <input matInput placeholder="Parent Phone">
                    </mat-form-field>
                    <mat-form-field class="eamil-box input-box-value">
                        <mat-label>Parent Email</mat-label>
                        <input matInput placeholder="Parent Email">
                    </mat-form-field>
                </div>

                <!-- Advance of Parent -->
                <div *ngIf="detail">
                    
                    <mat-form-field id="optional-field">
                        <mat-label>Govt Identification</mat-label>
                        <mat-select>
                            <mat-option>Aadhar</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field id="optional-field">
                        <mat-label>Aadhar Number</mat-label>
                        <input matInput type="number" placeholder="Aadhar Number"  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength = "16">
                    </mat-form-field>
                    <input type="file" />
                    <mat-form-field id="optional-field">
                        <mat-label>Address</mat-label>
                        <input matInput placeholder="Address">
                    </mat-form-field>
                    <mat-form-field id="optional-field">
                        <mat-label>Notes</mat-label>
                        <input matInput placeholder="Notes">
                    </mat-form-field>
                </div>
                <!-- End of Advance Parent -->

            </form>
        </div>


        <div>
            <img src="../../../assets/Frontdesk-images/Frame 3 (2).png">
        </div>



        <div class="heading-box">
            <p class="details-text">User Details (Optional)</p>
        </div>
        <div class="craete-parent-box">
            <div class="simple-bg-box">
                <mat-form-field class="pet-name-box" appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Name">
                </mat-form-field>
            </div>
            <div class="pet-species-box">


                <mat-form-field appearance="outline">
                    <mat-label>Pet Species</mat-label>
                    <mat-select>
                        <mat-option>Dogs</mat-option>
                        <mat-option>Cats</mat-option>
                        <mat-option>Fish</mat-option>
                        <mat-option>Birds</mat-option>
                        <mat-option>Reptiles</mat-option>
                        <mat-option>Small mammals (e.g. hamsters, guinea pigs, rabbits)</mat-option>
                        <mat-option>Horses</mat-option>
                        <mat-option>Turtles</mat-option>
                        <mat-option>Lizards</mat-option>
                        <mat-option>Snakes</mat-option>
                        <mat-option>Ferrets</mat-option>
                        <mat-option>Amphibians</mat-option>
                        <mat-option>Rats</mat-option>
                        <mat-option>Mice</mat-option>
                        <mat-option>Sugar gliders</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="radio-box">


                <mat-form-field appearance="outline">
                    <mat-label>Gender</mat-label>
                    <mat-select>
                        <mat-option>Male</mat-option>
                        <mat-option>Female</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div>

            <mat-form-field appearance="outline">
                <mat-label>Mood</mat-label>
                <mat-select>
                    <mat-option>Friendly</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Age</mat-label>
                <input matInput type="date" placeholder="Age">
            </mat-form-field>

            <!-- Advance of Pet -->
            <div *ngIf="detail">
                <mat-form-field appearance="outline">
                    <mat-label>Weight (kg)</mat-label>
                    <input matInput placeholder="Weight">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Preferred Doctor</mat-label>
                    <input matInput placeholder="Preferred Doctor">
                </mat-form-field>
                <!-- <mat-form-field appearance="outline">
                    <mat-label>Breed</mat-label>
                    <mat-select>
                        <mat-option>Great Dane</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <mat-form-field appearance="outline">
                    <mat-label>Color</mat-label>
                    <mat-select>
                        <mat-option>Brown</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Mother Name</mat-label>
                    <input matInput placeholder="Mother Name">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Local Authority LIC No." />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Rabies Tag" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Microship Number" />
                </mat-form-field>
            </div>

            <!-- End of Advance Pet -->
        </div>

        <div class="last-btn-box">
            <button class="cancel-btn" mat-flat-button routerLink="/FrontDesk/customers"
                routerLinkActive="active">Cancel</button>
            <button class="parent-btn" mat-flat-button>Create Parent</button>
        </div>
    </div>
</div>