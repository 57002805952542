import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';




import { IonicModule } from '@ionic/angular';

import { ManagerMainPageRoutingModule } from './manager-main-routing.module';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ManagerMainPage } from './manager-main.page';
import { componentModule } from 'src/app/components/component.module';
import { NgxPaginationModule } from 'ngx-pagination';
@NgModule({
  imports: [
    NgxPaginationModule,
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    IonicModule.forRoot({ animated: false }),
    componentModule,
    ManagerMainPageRoutingModule,


  ],
  declarations: [ManagerMainPage]
})
export class ManagerMainPageModule { }
