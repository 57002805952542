import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-branch',
  templateUrl: './new-branch.component.html',
  styleUrls: ['./new-branch.component.scss'],
})
export class NewBranchComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
