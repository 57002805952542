<div class="inventory-edit">
  <div class="center-modal-header">

    <div>
      <h1>New Medicine to Inventory{{addForm?.DosageFom}}</h1>

    </div>
    <div>
      <button class="close-button" (click)="closedialog()"><img src="../assets/close-icon.svg"></button>
    </div>
  </div>
</div>
<div class="formSize">
  <form class="example-form" style="max-width: 100%;" #Form="ngForm">
    <div style="display: flex;">
      <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
        <mat-label>Medicine</mat-label>
        <input matInput placeholder="Name" required type="text" [(ngModel)]="addForm.productName"
          name="addForm.productName" />
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Brand</mat-label>
        <input matInput placeholder="Name" [(ngModel)]="addForm.Brand" name="addForm.Brand">
      </mat-form-field>

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Dosage Form</mat-label>
        <mat-select [(ngModel)]="addForm.DosageFom" (ngModelChange)="liquid($event)" name="addForm.DosageFom"
          placeholder="Select" required>
          <mat-option value="Tablets">Tablets</mat-option>
          <mat-option value="Capsules">Capsules</mat-option>
          <mat-option value="Suspensions">Suspensions</mat-option>
          <mat-option value="Liquids">Liquids</mat-option>
          <mat-option value="Topical ointments">Topical ointments</mat-option>
          <mat-option value="Injections">Injections</mat-option>
          <mat-option value="Transdermal patches">Transdermal patches</mat-option>
        </mat-select>
        <!-- <input matInput placeholder="Dossage Form" [(ngModel)]="addForm.dosageform" name="addForm.dosageform"> -->
      </mat-form-field>


      <mat-form-field
        *ngIf="addForm?.DosageFom == 'Tablets' || addForm?.DosageFom == 'Capsules' || addForm?.DosageFom == 'Suspensions' || addForm?.DosageFom == 'Liquids' || addForm?.DosageFom == 'Injections' || addForm?.DosageFom == ''"
        class="example-full-width mando" [floatLabel]="'always'">
        <mat-label>Strength<span *ngIf="tab != '' || tab != null">({{tab}})</span></mat-label>
        <input matInput required type="text" [(ngModel)]="addForm.Strength" name="addForm.Strength"
          placeholder="Strength" />
      </mat-form-field>
      <mat-form-field
        *ngIf="addForm?.DosageFom == 'Tablets' || addForm?.DosageFom == 'Capsules' || addForm?.DosageFom == 'Suspensions' || addForm?.DosageFom == 'Liquids' || addForm?.DosageFom == 'Injections' || addForm?.DosageFom == ''"
        class="example-full-width" [floatLabel]="'always'">
        <mat-label>Dose per Kg</mat-label>
        <input matInput type="number" [(ngModel)]="addForm.Strength2" name="addForm.Strength2" placeholder="Dose" />
      </mat-form-field>

      <!-- <mat-form-field class="example-full-width" [floatLabel]="'always'">

        <mat-label> Strength</mat-label>
        <mat-select [(ngModel)]="addForm.Strength" name="addForm.Strength" placeholder="Select">
          <mat-option value="5 mg">5 mg</mat-option>
          <mat-option value="10 mg">10 mg</mat-option>
          <mat-option value="25 mg">25 mg</mat-option>
          <mat-option value="50 mg">50 mg</mat-option>
          <mat-option value="75 mg">75 mg</mat-option>
          <mat-option value="100 mg">100 mg</mat-option>
          <mat-option value="150 mg">150 mg</mat-option>
          <mat-option value="200 mg">200 mg</mat-option>
          <mat-option value="250 mg">250 mg</mat-option>
          <mat-option value="300 mg">300 mg</mat-option>
          <mat-option value="400 mg">400 mg</mat-option>
          <mat-option value="500 mg">500 mg</mat-option>
          <mat-option value="750 mg">750 mg</mat-option>
          <mat-option value="1000 mg">1000 mg</mat-option>
          <mat-option value="1500 mg">1500 mg</mat-option>
          <mat-option value="2000 mg">2000 mg</mat-option>
        </mat-select>
      </mat-form-field> -->
    </div>
    <div style="display: flex;">

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Current Quantity
        </mat-label>
        <input type="number" matInput placeholder="No's" [(ngModel)]="addForm.CurrentQuantity" name="addForm.CurrentQuantity">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Reorder Level

        </mat-label>
        <input matInput placeholder="No's" [(ngModel)]="addForm.ReOrderLevel" name="addForm.ReOrderLevel">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Reorder Quantity

        </mat-label>
        <input type="number" matInput placeholder="No's" [(ngModel)]="addForm.ReQuantity" name="addForm.ReQuantity">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Free Samples</mat-label>
        <input matInput placeholder="Free Samples" [(ngModel)]="addForm.freeSamples" name="addForm.freeSamples">
      </mat-form-field>

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Lot/Batch No.</mat-label>
        <input type="number" matInput placeholder="Number" [(ngModel)]="addForm.BatchNo" name="addForm.BatchNo">
      </mat-form-field>
    </div>
    <div style="display: flex;">


      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Supplier
        </mat-label>
        <input matInput placeholder="Name" [(ngModel)]="addForm.SupplierInfo" name="addForm.SupplierInfo">
      </mat-form-field>
      <mat-form-field appearance="fill" class="example-full-width" [floatLabel]="'always'">
        <mat-label>Expiration Date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="addForm.ExpirationDate" name="addForm.ExpirationDate">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
        <mat-label>Purchase Price</mat-label>
        <input  type="number" matInput placeholder="Rs" [(ngModel)]="addForm.PurchasePrice" name="addForm.PurchasePrice" required="">
      </mat-form-field>
      <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
        <mat-label>Retail Price</mat-label>
        <input type="number" matInput placeholder="Rs" [(ngModel)]="addForm.RetailPrice" name="addForm.RetailPrice" required="">
      </mat-form-field>
    </div>
    <div style="display: flex;">


      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Max Retail Dis(%)</mat-label>
        <input type="number" matInput placeholder="%" [(ngModel)]="addForm.maxRetailDiscount" name="addForm.maxRetailDiscount">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>HSN Code</mat-label>
        <input matInput placeholder="HSN Code" [(ngModel)]="addForm.HSN" name="addForm.HSN">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>SGST</mat-label>
        <input type="number" matInput placeholder="SGST" [(ngModel)]="addForm.SGST" name="addForm.SGST">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>CGST</mat-label>
        <input type="number" matInput placeholder="CGST" [(ngModel)]="addForm.CGST" name="addForm.CGST">
      </mat-form-field>

    </div>
    <!-- <div style="display: flex;">

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Indication for use </mat-label>
        <input matInput placeholder="Indication for use" [(ngModel)]="addForm.indicationuse"
          name="addForm.indicationuse">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Contra-indications </mat-label>
        <input matInput placeholder="Contra-indications" [(ngModel)]="addForm.contraindications"
          name="addForm.contraindications">
      </mat-form-field>
    </div> -->
    <!-- <div style="display: flex;">


     
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Side Effects</mat-label>
        <input matInput placeholder="Side Effects" [(ngModel)]="addForm.sideeffects" name="addForm.sideeffects">
      </mat-form-field>
    </div> -->


    <div style="display: flex;">
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Storage Temperatur</mat-label>
        <mat-select [(ngModel)]="addForm.StorageTemperature" name="addForm.StorageTemperature" placeholder="Select">
          <mat-option value="Room Temperature">Room Temperature</mat-option>
          <mat-option value="Refrigerator">Refrigerator</mat-option>
          <mat-option value="Frozen">Frozen</mat-option>

        </mat-select>
        <!-- <input matInput placeholder="Storage Temperature" [(ngModel)]="addForm.storageTemp" name="addForm.storageTemp"> -->
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Barcode/SKU</mat-label>
        <input type="number" matInput placeholder="No." readonly [(ngModel)]="addForm.barCode" name="addForm.barCode">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Location
        </mat-label>
        <input matInput placeholder="Cabin Name etc" [(ngModel)]="addForm.Location" name="addForm.Location">
      </mat-form-field>
    </div>

  </form>
  <div class="modalFooter">
    <p>
      All set to add this medicine? Just give it one final check
    </p>
    <div>
      <button class="modalSecondaryBt" mat-raised-button type="submit" (click)="closedialog()">Cancel</button>
      <button class="modalPrimaryBt" mat-raised-button type="submit" [disabled]="Form.invalid" (click)="save()">Create
        Medicine</button>

    </div>


  </div>
</div>